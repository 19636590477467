import React, { useState, useEffect, useRef } from "react";
import {
  MainContainer,
  Form,
  InputCont,
  InputField,
  TwoCol,
  InputTextArea,
  SubmitCont,
  CheckTitle,
  CheckCont,
  CheckLable,
  CheckLabelMain,
  CheckMainCont,
  Price,
  Paypal,
  SubButton,
} from "./RequestForm.elements";

import axios from "axios";

import Checkbox from "./Checkbox";
import FileUploader from "./FileUploader";

const RequestForm = () => {
  const [subscriberInfo, setSubscriberInfo] = useState(false);
  const [cdrs, setCdrs] = useState(false);
  const [billingInfo, setBillingInfo] = useState(false);
  const [showForm, setShowForm] = useState(true);
  const [showSubmit, setShowSubmit] = useState(false);
  const [showMsg, setShowMsg] = useState(
    "Please Pay $100 to process the request"
  );
  const [formData, setFormData] = useState({
    form: "request",
    name: "",
    phone: "",
    fax: "",
    subscriberInfo: "",
    cdrs: "",
    billingInfo: "",
    targetNumbers: "",
    startDate: "",
    endDate: "",
    message: "",
    agency: "",
  });

  const paypal = useRef();

  const handleCheckboxChange = (event) => {
    if (event.target.name === "subscriberInfo") {
      setSubscriberInfo((prevState) => !prevState);
      setFormData((prev) => ({
        ...prev,
        subscriberInfo: event.target.value,
      }));
    } else if (event.target.name === "cdrs") {
      setCdrs((prevState) => !prevState);
      setFormData((prev) => ({
        ...prev,
        cdrs: event.target.value,
      }));
    } else if (event.target.name === "billingInfo") {
      setBillingInfo((prevState) => !prevState);
      setFormData((prev) => ({
        ...prev,
        billingInfo: event.target.value,
      }));
    }
  };

  const handleFile = (fileUploaded) => {
    console.log(fileUploaded.name);
  };

  const submitData = async (e) => {
    e.preventDefault();
    const {
      name,
      agency,
      phone,
      fax,
      subscriberInfo,
      cdrs,
      billingInfo,
      targetNumbers,
      startDate,
      endDate,
      message,
    } = e.target.elements;

    const data = {
      form: "request",
      name: name.value,
      agency: agency.value,
      phone: phone.value,
      fax: fax.value,
      subscriberInfo: subscriberInfo.value,
      cdrs: cdrs.value,
      billingInfo: billingInfo.value,
      targetNumbers: targetNumbers.value,
      startDate: startDate.value,
      endDate: endDate.value,
      message: message.value,
    };
    const response = await axios.post(
      "https://register.talkasiavoip.com/public/api/forms",
      {
        data: data,
      }
    );
    setShowForm(false);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    var FUNDING_SOURCES = [window.paypal.FUNDING.PAYPAL];
    FUNDING_SOURCES.forEach(function (fundingSource) {
      var button = window.paypal.Buttons({
        fundingSource: fundingSource,
        createOrder: (data, actions, err) => {
          return actions.order.create({
            intent: "CAPTURE",
            purchase_units: [
              {
                description: "helllllo",

                amount: {
                  currency_code: "USD",
                  value: 100,
                },
              },
            ],
          });
        },
        style: {
          layout: "horizontal",
          color: "gold",
          height: 44,
          shape: "rect",
          tagline: true,
          label: "paypal",
        },
        onApprove: async (data, actions) => {
          const order = await actions.order.capture();
          console.log("successful order:" + order.status);

          if (order.status === "COMPLETED") {
            setShowSubmit(true);
            setShowMsg(
              "Thank You! Your payment has been received. Please click the below button to submit the form."
            );
          }
        },
        onError: (err) => {
          console.log(err);
        },
      });

      if (button.isEligible()) {
        button.render(paypal.current);
      }
    });
  }, []);

  return (
    <>
      <MainContainer>
        {showForm ? (
          <Form onSubmit={(e) => submitData(e)}>
            <InputCont>
              <InputField
                type="text"
                name="name"
                placeholder="Full Name"
                onChange={(e) =>
                  setFormData((prev) => ({ ...prev, name: e.target.value }))
                }
              />
              {/* <Icon src="assets/images/contact-input-icon-1.svg" /> */}
            </InputCont>
            <InputCont>
              <InputField
                type="text"
                name="agency"
                placeholder="Authorized Agency / Department *"
                onChange={(e) =>
                  setFormData((prev) => ({ ...prev, email: e.target.value }))
                }
              />
              {/* <Icon src="assets/images/contact-input-icon-2.svg" /> */}
            </InputCont>
            <TwoCol>
              <InputCont>
                <InputField
                  type="text"
                  name="phone"
                  placeholder="Phone"
                  onChange={(e) =>
                    setFormData((prev) => ({ ...prev, phone: e.target.value }))
                  }
                />
              </InputCont>

              <InputCont>
                <InputField
                  type="text"
                  name="fax"
                  placeholder="fax"
                  onChange={(e) =>
                    setFormData((prev) => ({ ...prev, fax: e.target.value }))
                  }
                />
              </InputCont>
            </TwoCol>

            <InputCont>
              <CheckTitle>
                Nature of Request (check all that apply) *
              </CheckTitle>

              <CheckMainCont>
                <CheckCont>
                  <CheckLabelMain>
                    <Checkbox
                      checked={subscriberInfo}
                      onChange={handleCheckboxChange}
                      value={1}
                      name="subscriberInfo"
                    />
                    <CheckLable style={{ marginLeft: 8 }}>
                      Subscriber Info
                    </CheckLable>
                  </CheckLabelMain>
                </CheckCont>

                <CheckCont>
                  <CheckLabelMain>
                    <Checkbox
                      checked={cdrs}
                      onChange={handleCheckboxChange}
                      value={1}
                      name="cdrs"
                    />
                    <CheckLable style={{ marginLeft: 8 }}>CDRs</CheckLable>
                  </CheckLabelMain>
                </CheckCont>

                <CheckCont>
                  <CheckLabelMain>
                    <Checkbox
                      checked={billingInfo}
                      onChange={handleCheckboxChange}
                      value={1}
                      name="billingInfo"
                    />
                    <CheckLable style={{ marginLeft: 8 }}>
                      Billing Info
                    </CheckLable>
                  </CheckLabelMain>
                </CheckCont>
              </CheckMainCont>
            </InputCont>

            <InputCont>
              <InputField
                type="text"
                placeholder="Target Telephone Number(s) *"
                name="targetNumbers"
                onChange={(e) =>
                  setFormData((prev) => ({
                    ...prev,
                    targetNumbers: e.target.value,
                  }))
                }
              />
            </InputCont>

            <TwoCol>
              <InputCont>
                <InputField
                  type="date"
                  placeholder="Request Start Date"
                  name="startDate"
                  onChange={(e) =>
                    setFormData((prev) => ({
                      ...prev,
                      startDate: e.target.value,
                    }))
                  }
                />
              </InputCont>

              <InputCont>
                <InputField
                  type="date"
                  placeholder="Request End Date"
                  name="endDate"
                  onChange={(e) =>
                    setFormData((prev) => ({
                      ...prev,
                      endDate: e.target.value,
                    }))
                  }
                />
              </InputCont>
            </TwoCol>

            <InputCont>
              <CheckTitle>
                Attach Subpoena, Court Order, or Warrant *
              </CheckTitle>
            </InputCont>

            <InputCont>
              <FileUploader handleFile={handleFile} />
            </InputCont>

            <InputCont>
              <InputTextArea
                placeholder="Message"
                name="message"
                onChange={(e) =>
                  setFormData((prev) => ({ ...prev, message: e.target.value }))
                }
              />
            </InputCont>
            <SubmitCont>
              <Price onClick={submitData}>{showMsg}</Price>
              {!showSubmit ? (
                <Paypal>
                  <div ref={paypal}></div>
                </Paypal>
              ) : (
                ""
              )}
              {showSubmit ? <SubButton type="submit">Submit</SubButton> : ""}
            </SubmitCont>
          </Form>
        ) : (
          "Thank You! your information has been submitted."
        )}
      </MainContainer>
    </>
  );
};

export default RequestForm;
