import React from "react";
import {
  MainContainer,
  PriceTableContainer,
  SinglePriceTable,
  PriceContentContainer,
  PriceHeadingContainer,
  PriceHeading,
  NumberOfAgents,
  PriceImage,
  PriceFeatuesContainer,
  SingleFeature,
  GetStarted,
  Bold,
} from "./PriceTables.elements";

const PriceTables = () => {
  return (
    <MainContainer>
      <PriceTableContainer>
        <SinglePriceTable>
          <PriceHeadingContainer>
            <PriceHeading>Basic</PriceHeading>
          </PriceHeadingContainer>

          <PriceContentContainer>
            <NumberOfAgents> &lt; 10 Agents</NumberOfAgents>
            <PriceImage src="assets/images/price-1.svg" />
            <PriceFeatuesContainer>
              <SingleFeature>
                Free Dialer (No Setup No Monthly Fee)
              </SingleFeature>
              <SingleFeature>
                Outgoing Call <Bold>0.0055$</Bold>/min 6/6
              </SingleFeature>
              <SingleFeature>Cloud Based Hardware</SingleFeature>
              <SingleFeature>Dedicated Support</SingleFeature>
              <SingleFeature>
                Minimum <Bold>50$</Bold> Deposit Required
              </SingleFeature>
            </PriceFeatuesContainer>
            <GetStarted
              onClick={() =>
                window.open("https://register.talkasiavoip.com/", "_blank")
              }
            >
              Get Started
            </GetStarted>
          </PriceContentContainer>
        </SinglePriceTable>

        <SinglePriceTable>
          <PriceHeadingContainer style={{ background: "#36A369" }}>
            <PriceHeading style={{ color: "#fff" }}>Starter</PriceHeading>
          </PriceHeadingContainer>

          <PriceContentContainer>
            <NumberOfAgents>10-20 Agents</NumberOfAgents>
            <PriceImage src="assets/images/price-2.svg" />
            <PriceFeatuesContainer>
              <SingleFeature>
                Free Dialer (No Setup No Monthly Fee)
              </SingleFeature>
              <SingleFeature>
                Outgoing Call <Bold>0.0050$</Bold>/min 6/6
              </SingleFeature>
              <SingleFeature>Cloud Based Hardware</SingleFeature>
              <SingleFeature>Dedicated Support</SingleFeature>
              <SingleFeature>
                Minimum <Bold>50$</Bold> Deposit Required
              </SingleFeature>
            </PriceFeatuesContainer>
            <GetStarted
              onClick={() =>
                window.open("https://register.talkasiavoip.com/", "_blank")
              }
            >
              Get Started
            </GetStarted>
          </PriceContentContainer>
        </SinglePriceTable>

        <SinglePriceTable>
          <PriceHeadingContainer>
            <PriceHeading>Pro</PriceHeading>
          </PriceHeadingContainer>

          <PriceContentContainer>
            <NumberOfAgents>&gt; 20 Agents</NumberOfAgents>
            <PriceImage src="assets/images/price-3.svg" />
            <PriceFeatuesContainer>
             
              <SingleFeature>
                Outgoing Call <Bold>0.0045$</Bold>/min 6/6
              </SingleFeature>
              <SingleFeature>
                Dedicated Hardware @ <Bold>3$</Bold>/Agent/month
              </SingleFeature>
              <SingleFeature>
                Cloud Based Hardware @ <Bold>1$</Bold>/Agent/month
              </SingleFeature>
              <SingleFeature>Dedicated Support</SingleFeature>
              <SingleFeature>
                Minimum <Bold>100$</Bold> Deposit Required
              </SingleFeature>
            </PriceFeatuesContainer>
            <GetStarted
              onClick={() =>
                window.open("https://register.talkasiavoip.com/", "_blank")
              }
            >
              Get Started
            </GetStarted>
          </PriceContentContainer>
        </SinglePriceTable>
      </PriceTableContainer>
    </MainContainer>
  );
};

export default PriceTables;
