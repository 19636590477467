import React, { useState } from "react";
import {
  MainContainer,
  HeadingCont,
  Heading,
 
  ContentCont,
  MenuCont,
  UL,
  ListItem,
  DetailsCont,
  DetHeading,
  DetDesc,
  RatingImage,
  UserInfo,
  UserImage,
  UserDet,
  UserDetName,
  UserDetDate,
} from "./Testimonials.elements";

const Testimonials = () => {
  const [desc, setDesc] = useState(1);
  const [cont, setCont] = useState();

  const changeContent = (x) => {
    setDesc(x);
    testimonial(x);
  };

  const testimonial = (x) => {
    if (x === 1) {
      setCont(
        <>
          <DetHeading>Very Responsive Support</DetHeading>
          <RatingImage src="assets/images/stars.svg" />
          <DetDesc>
            TalkAsia was incredibly fast to implement for my team of 250+ agents
            .In my opinion, the responsive support they provided and the speed
            with which we were able to get our teams up and running was
            remarkable.They allows us to compete with the larger players. Many
            of those large contact centers have huge IT departments, but we can
            keep ours small thanks to TalkAsia.
          </DetDesc>
        </>
      );
    } else if (x === 2) {
      setCont(
        <>
          <DetHeading>They handle whatever we throw at them</DetHeading>
          <RatingImage src="assets/images/stars.svg" />
          <DetDesc>
            Sometimes with little warning we need them to handle double the call
            volume, and TalkAsia always comes through. It means we keep our
            customers happy while capturing maximum revenue.TalkAsia’s digital
            workspaces have allowed us to acquire more practices in a faster and
            more profitable way. That is resulting in bottom-line cost savings
            and top-line business benefits.
          </DetDesc>
        </>
      );
    } else if (x === 3) {
      setCont(
        <>
          <DetHeading>Amazing VoIP service</DetHeading>
          <RatingImage src="assets/images/stars.svg" />
          <DetDesc>
            Best-in-class account customer support and account management, with
            kind, dedicated professionals who truly care about their jobs. Sound
            quality is also really good, I have rarely experienced any issues.
            Highly recommended.
          </DetDesc>
        </>
      );
    }
  };

  return (
    <>
      <MainContainer>
        <HeadingCont>
          <Heading>Meet Client Satisfaction</Heading>
          {/* <SubHeading>
            Numerous features make it possible to customize the system in
            accordance with all your needs.
          </SubHeading> */}
        </HeadingCont>

        <ContentCont>
          <MenuCont>
            <UL>
              <ListItem
                onClick={() => changeContent(1)}
                style={{
                  background: desc === 1 ? "#36a469" : "",
                  color: desc === 1 ? "#fff" : "",
                }}
              >
                <UserInfo>
                  <UserImage src="assets/images/user-2.jpg" />
                  <UserDet>
                    <UserDetName
                      style={{
                        color: desc === 1 ? "#fff" : "",
                      }}
                    >
                      Jimmy
                    </UserDetName>
                    <UserDetDate
                      style={{
                        color: desc === 1 ? "#fff" : "",
                      }}
                    >
                      Co-founder Eracon Technologies
                    </UserDetDate>
                  </UserDet>
                </UserInfo>
                {/* <SocialIcon src="assets/images/google.svg" /> */}
              </ListItem>
              <ListItem
                onClick={() => changeContent(2)}
                style={{
                  background: desc === 2 ? "#36a469" : "",
                  color: desc === 2 ? "#fff" : "",
                }}
              >
                <UserInfo>
                  <UserImage src="assets/images/user-3.jpg" />
                  <UserDet>
                    <UserDetName
                      style={{
                        color: desc === 2 ? "#fff" : "",
                      }}
                    >
                      Malik Farhan
                    </UserDetName>
                    <UserDetDate
                      style={{
                        color: desc === 2 ? "#fff" : "",
                      }}
                    >
                      CEO Next Gen Technologies Private Limited
                    </UserDetDate>
                  </UserDet>
                </UserInfo>
                {/* <SocialIcon src="assets/images/google.svg" /> */}
              </ListItem>
              <ListItem
                onClick={() => changeContent(3)}
                style={{
                  background: desc === 3 ? "#36a469" : "",
                  color: desc === 3 ? "#fff" : "",
                }}
              >
                <UserInfo>
                  <UserImage src="assets/images/user-1.jpg" />
                  <UserDet>
                    <UserDetName
                      style={{
                        color: desc === 3 ? "#fff" : "",
                      }}
                    >
                      Syed Bilal
                    </UserDetName>
                    <UserDetDate
                      style={{
                        color: desc === 3 ? "#fff" : "",
                      }}
                    >
                      CEO TelSolution Networks
                    </UserDetDate>
                  </UserDet>
                </UserInfo>
                {/* <SocialIcon src="assets/images/google.svg" /> */}
              </ListItem>
            </UL>
          </MenuCont>
          <DetailsCont>
            {desc === 1 ? (
              <>
                <DetHeading>Very Responsive Support</DetHeading>
                <RatingImage src="assets/images/stars.svg" />
                <DetDesc>
                  TalkAsia was incredibly fast to implement for my team of 250+
                  agents .In my opinion, the responsive support they provided
                  and the speed with which we were able to get our teams up and
                  running was remarkable.They allows us to compete with the
                  larger players. Many of those large contact centers have huge
                  IT departments, but we can keep ours small thanks to TalkAsia.
                </DetDesc>
              </>
            ) : (
              cont
            )}
          </DetailsCont>
        </ContentCont>
      </MainContainer>
    </>
  );
};

export default Testimonials;
