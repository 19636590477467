import React from "react";
import {
  MainContainer,
  Heading,
  FeaturesContainer,
  SingleFeature,
  FeatureHeading,
  FeatureDesc,
} from "./DialerFulfilSec.elements";

const DialerFulfilSec = () => {
  return (
    <MainContainer>
      <Heading>Call center features</Heading>

      <FeaturesContainer>
        <SingleFeature>
          <FeatureHeading>Call Routing</FeatureHeading>
          <FeatureDesc>
            Direct calls to the correct teammates every time by customizing
            distribution and ring rules.
          </FeatureDesc>
        </SingleFeature>

        <SingleFeature>
          <FeatureHeading>Call Monitoring</FeatureHeading>
          <FeatureDesc>
            Accelerate employee training and boost call quality assurance by
            monitoring live calls.
          </FeatureDesc>
        </SingleFeature>

        <SingleFeature>
          <FeatureHeading>Business Hours</FeatureHeading>
          <FeatureDesc>
            Set customized schedules to confirm exactly when each of your
            numbers are available to receive calls.
          </FeatureDesc>
        </SingleFeature>

        <SingleFeature>
          <FeatureHeading>After-Call Work</FeatureHeading>
          <FeatureDesc>
            After a call, give your team the time to tag, assign, and prepare
            for the next conversation.
          </FeatureDesc>
        </SingleFeature>

        <SingleFeature>
          <FeatureHeading>Call Center Analytics</FeatureHeading>
          <FeatureDesc>
            Keep track of all your call center metrics—such as wait time, missed
            call rate, and call volume—in one place.{" "}
          </FeatureDesc>
        </SingleFeature>

        <SingleFeature>
          <FeatureHeading>Call Conferencing</FeatureHeading>
          <FeatureDesc>
            Make it a collaborative conversation using TalkAsia call
            conferencing feature.
          </FeatureDesc>
        </SingleFeature>

        <SingleFeature>
          <FeatureHeading>Call Queuing</FeatureHeading>
          <FeatureDesc>
            Give inbound callers the option to remain in queue until one of your
            agents is available to talk.
          </FeatureDesc>
        </SingleFeature>

        <SingleFeature>
          <FeatureHeading>Call Recording</FeatureHeading>
          <FeatureDesc>
            Review call recordings to help confirm details, monitor quality, and
            guide training sessions.
          </FeatureDesc>
        </SingleFeature>

        <SingleFeature>
          <FeatureHeading>Blocklist Numbers</FeatureHeading>
          <FeatureDesc>
            Eliminate spam callers and bots so you can focus more on the
            conversations your business cares about.{" "}
          </FeatureDesc>
        </SingleFeature>
      </FeaturesContainer>
    </MainContainer>
  );
};

export default DialerFulfilSec;
