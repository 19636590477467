import React, { useEffect } from "react";
import {
  Navbar,
  Hero,
  NewSection,
  ServicesWithImage,
  AmazonFeatures,
  LeadSec,
  Footer,
} from "../Components";

const AmazonPage = () => {
  const hero = {
    heading: "Increase Your Advertising Sales On Amazon.",
    subHeading: "",
    image: "/assets/images/amazon-hero.png",
    headingSize: "32px",
  };

  const servicesSecTwo = {
    heading: "Why Choose Us?",
    desc: [
      <b>HOW DOES WORKFLOW WORK?</b>,
      <br />,
      "Create engagement workflows for sending/receiving SMS & Calls. Get started with the enrollment of contacts and engage through multiple nodes including webhooks for integrations with 3rd parties.",
      <br />,
      <br />,
      <b>WHY USE TRACKING?</b>,
      <br />,
      "Not only can you orchestrate conversations and visually manage engagement paths for texts and calls but you will be able to dynamically route these conversations to the most appropriate destination.",
    ],
    // linkText: "Book a free Call",
    image: "assets/images/amazon-service-1.png",
    imageAlign: "row",
  };

  const NewFeatures = {
    heading: "THE FULL-SERVICE AMAZON AGENCY",

    featureOneHeading: "Strategy",
    featureOneDesc: [
      "Comprehensive audits to identify growth opportunities.",
      <br />,
      <br />,
      <br />,
    ],
    featureOneIcon: "/assets/images/amazon-feature-1.svg",

    featureTwoHeading: "Management",
    featureTwoDesc: [
      "We can manage everything, or just the aspects you need help with.",
      <br />,
      <br />,
    ],
    featureTwoIcon: "/assets/images/amazon-feature-2.svg",

    featureThreeHeading: "Optimised Content",
    featureThreeDesc: [
      "Search focused copy, images and video by our in-house team.",
      <br />,
      <br />,
    ],
    featureThreeIcon: "/assets/images/amazon-feature-3.svg",

    featureFourHeading: "Sales & Advertising",
    featureFourDesc: [
      "Results, delivered & reported.",
      <br />,
      <br />,
      <br />,
      <br />,
    ],
    featureFourIcon: "/assets/images/amazon-feature-4.svg",
    bg: "#FFFBF5",
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Navbar />
      <Hero
        heading={hero.heading}
        subHeading={hero.subHeading}
        image={hero.image}
        imageStyle={{ marginTop: "40px" }}
        headingSize={hero.headingSize}
      />
      <ServicesWithImage
        heading={servicesSecTwo.heading}
        desc={servicesSecTwo.desc}
        linkText={servicesSecTwo.linkText}
        image={servicesSecTwo.image}
        imageAlign={servicesSecTwo.imageAlign}
      />

      <NewSection
        position="center"
        heading={NewFeatures.heading}
        featureOneHeading={NewFeatures.featureOneHeading}
        featureTwoHeading={NewFeatures.featureTwoHeading}
        featureThreeHeading={NewFeatures.featureThreeHeading}
        featureFourHeading={NewFeatures.featureFourHeading}
        featureOneDesc={NewFeatures.featureOneDesc}
        featureTwoDesc={NewFeatures.featureTwoDesc}
        featureThreeDesc={NewFeatures.featureThreeDesc}
        featureFourDesc={NewFeatures.featureFourDesc}
        featureOneIcon={NewFeatures.featureOneIcon}
        featureTwoIcon={NewFeatures.featureTwoIcon}
        featureThreeIcon={NewFeatures.featureThreeIcon}
        featureFourIcon={NewFeatures.featureFourIcon}
        bg={NewFeatures.bg}
      />

      <AmazonFeatures />
      <LeadSec />
      <Footer />
    </>
  );
};

export default AmazonPage;
