import React from "react";
import {
  MainContainer,
  HeadingContainer,
  SubHeading,
  Heading,
  FeaturesContainer,
  SingleFeature,
  Icon,
  FeatureHeading,
  FeatureDesc,
} from "./NewSection.elements";

const NewSection = ({
  heading,
  subHeading,
  featureOneHeading,
  featureTwoHeading,
  featureThreeHeading,
  featureFourHeading,
  featureOneDesc,
  featureTwoDesc,
  featureThreeDesc,
  featureFourDesc,
  featureOneIcon,
  featureTwoIcon,
  featureThreeIcon,
  featureFourIcon,
  position,
  bg,
}) => {
  return (
    <MainContainer bg={bg}>
      <HeadingContainer position={position}>
        {subHeading ? <SubHeading>{subHeading}</SubHeading> : ""}
        <Heading position={position}>{heading}</Heading>
      </HeadingContainer>

      <FeaturesContainer>
        <SingleFeature>
          <Icon src={featureOneIcon} />
          <FeatureHeading>{featureOneHeading}</FeatureHeading>
          <FeatureDesc>{featureOneDesc}</FeatureDesc>
        </SingleFeature>
        <SingleFeature>
          <Icon src={featureTwoIcon} />
          <FeatureHeading>{featureTwoHeading}</FeatureHeading>
          <FeatureDesc>{featureTwoDesc}</FeatureDesc>
        </SingleFeature>
        <SingleFeature>
          <Icon src={featureThreeIcon} />
          <FeatureHeading>{featureThreeHeading}</FeatureHeading>
          <FeatureDesc>{featureThreeDesc}</FeatureDesc>
        </SingleFeature>
        <SingleFeature>
          <Icon src={featureFourIcon} />
          <FeatureHeading>{featureFourHeading}</FeatureHeading>
          <FeatureDesc>{featureFourDesc}</FeatureDesc>
        </SingleFeature>
      </FeaturesContainer>
    </MainContainer>
  );
};

export default NewSection;
