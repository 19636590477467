import { Link } from "react-router-dom";
import styled from "styled-components";

export const MainContainer = styled.div`
  min-height: 575px;
  max-width: 1440px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 980px) {
  }

  @media screen and (max-width: 690px) {
    padding: 40px 0px;
  }
`;

export const HeadingCont = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 45px;
`;

export const Heading = styled.h2`
  max-width: 603px;
  width: 100%;
  font-family: "Poppins";
  font-style: normal;
  font-weight: bold;
  font-size: 44px;
  line-height: 56px;
  text-align: center;
  color: #262626;
  margin-bottom: 19px;
`;

export const Desc = styled.p`
  max-width: 523px;
  width: 100%;
  font-family: "Poppins";
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 26px;
  text-align: center;
  color: #383838;
`;

export const FormSec = styled.div`
  max-width: 1152px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Form = styled.form`
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media screen and (max-width: 1040px) {
    flex-direction: column;
  }
`;

export const TextInput = styled.input`
  max-width: 434.7px;
  width: 100%;
  height: 80.1px;
  background: #ffffff;
  border: 1px solid #cccccc;
  box-sizing: border-box;
  font-family: "Inter";
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  padding: 0px 27px;
  outline: none;

  @media screen and (max-width: 1040px) {
    max-width: 60%;
    margin-bottom: 20px;
  }

  @media screen and (max-width: 690px) {
    max-width: 80%;
  }

  :focus {
    border-color: #36a469;
  }

  ::placeholder {
    color: #cccccc;
  }
`;

export const SubmitBtn = styled.button`
  width: 211.5px;
  height: 80.1px;
  background: #36a469;
  font-family: "Poppins";
  font-style: normal;
  font-weight: normal;
  font-size: 33px;
  color: #ffffff;
  text-transform: uppercase;
  border: none;
  outline: none;
  cursor: pointer;

  @media screen and (max-width: 1040px) {
    width: 60%;
  }

  @media screen and (max-width: 690px) {
    width: 80%;
  }
`;

export const LeadButton = styled(Link)`
  width: 204px;
  height: 55px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #1f8049;
  border-radius: 42px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  text-align: center;
  color: #ffffff;
  text-decoration: none;
  cursor: pointer;
`;

export const LeadBtnImg = styled.img`
  margin-right: 22px;
`;
// export const Desc =styled.div``;
