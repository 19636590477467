import React, { useEffect } from "react";
import {
  Navbar,
  AboutHero,
  AboutSecOne,
  AboutSecTwo,
  AboutSecThree,
  AboutSecFour,
  Testimonials,
  LeadSec,
  Footer,
} from "../Components";

const AboutUsPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Navbar color="#535353" />
      <AboutHero heading="Dedicated Teams For Your Dedicated Dreams." />
      <AboutSecOne />
      <AboutSecTwo />
      <AboutSecThree />
      <AboutSecFour />
      <Testimonials />
      <LeadSec />
      <Footer />
    </>
  );
};

export default AboutUsPage;
