import { Link } from "react-router-dom";
import styled from "styled-components";

export const MainContainer = styled.div`
  min-height: 744px;
  max-width: 1440px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 980px) {
    flex-direction: column;
  }
`;

export const SecOne = styled.div`
  min-height: 744px;
  height: 100%;
  width: 57%;
  background-image: url("assets/images/footer-bubble.svg");
  background-position: left;
  background-size: contain;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding-left: 12%;

  @media screen and (max-width: 980px) {
    min-height: 500px;
    width: 100%;
    align-items: center;
    background-image: none;
    padding-left: 0px;
  }

  @media only screen and (min-width: 1080px) and (max-width: 1180px) {
    padding-left: 9%;
  }

  @media only screen and (min-width: 960px) and (max-width: 1080px) {
    padding-left: 8%;
  }
`;

export const SecTwo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  flex-grow: 1;
`;

export const ContOne = styled.div`
  height: 108px;
  width: 350px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  margin-bottom: 39px;

  @media screen and (max-width: 420px) {
    justify-content: space-evenly;
  }
`;

export const Heading = styled.h2`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 40px;
  color: #ffffff;

  @media screen and (max-width: 980px) {
    color: #000;
  }
`;

export const FooterLink = styled(Link)`
  width: 192px;
  text-decoration: none;
  font-family: "Poppins";
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 26px;
  color: #f8f8f8;

  @media screen and (max-width: 980px) {
    color: #000;
  }

  @media screen and (max-width: 760px) {
    width: 160px;
  }
`;

export const ContTwo = styled.div`
  height: 108px;
  width: 350px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;

  @media screen and (max-width: 420px) {
    justify-content: space-evenly;
  }
`;

export const MenuOne = styled.div`
  width: 189px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  :nth-child(2) {
    max-width: 130px;
  }
`;

export const ContactCont = styled.div`
  width: 399px;
  height: 371px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;

  @media screen and (max-width: 480px) {
    padding: 0px 20px;
  }
`;

export const ContHeading = styled.h2`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 33px;
  line-height: 50px;
  color: #000000;
  margin-bottom: 41px;

  @media screen and (max-width: 420px) {
    padding: 0px 40px;
  }
`;

export const ContactDetCont = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-bottom: 63px;

  @media screen and (max-width: 420px) {
    padding: 0px 40px;
  }
`;

export const SocialCont = styled.div`
  margin-bottom: 28.4px;

  @media screen and (max-width: 420px) {
    padding: 0px 40px;
  }
`;

export const CopyCont = styled.div`
  @media screen and (max-width: 420px) {
    padding: 0px 40px;
  }
`;

export const PhoneCont = styled.div`
  display: flex;
  margin-bottom: 17px;
  margin-right: 13px;

  &:nth-child(2) {
    margin-right: 0px;
  }
`;

export const CountryIcon = styled.img`
  margin-right: 15px;
`;

export const ContactNum = styled.p`
  font-family: "Poppins";
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 22px;
  color: #272727;
`;

export const SocialLink = styled(Link)``;

export const SocialIcon = styled.img`
  margin-right: 10.6px;
`;

export const CopyText = styled.p`
  max-width: 243px;
  width: 100%;
  font-family: "Poppins";
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 22px;
  color: #989898;
`;

export const BubbleRight = styled.img`
  position: absolute;
  right: 0;
  z-index: -9;
`;

export const FG = styled.img`
  margin-top: 12px;
`;
// export const Desc =styled.div``;
// export const Desc =styled.div``;
