import React from "react";
import {
  MainContainer,
  TermText,
  Bold,
  BoldHeading,
} from "./ServiceAgreement.elements";

const ServiceAgreement = () => {
  return (
    <MainContainer>
      <TermText>
        This MSA is made and entered into by and between the individual or
        entity who is executing or signing in this MSA as the customer
        (“Customer”) and TalkAsia VoIP LLC (“Company”). For purposes of this
        MSA, Customer and Company shall be referred to collectively as the
        “Parties” and each individually as a “Party.”
      </TermText>

      <TermText>
        <Bold>WHEREAS</Bold>, Company supplies certain software, calling
        communication services platforms, technology and/or consulting services
        of any kind, including, without limitation, the Software (as defined in
        the End User License Agreement (“EULA”) appended hereto, which is
        incorporated herein by reference), and consulting services, if any,
        (collectively, the “Services”), as further specified below, in the EULA
        and the Order Form, (if any), respectively, and Customer desires to
        utilize said Services; and
      </TermText>
      <TermText>
        <Bold>WHEREAS</Bold>, for purposes of this MSA, the term “Service Order”
        shall refer to the Scope, Order Form and Credit Card Authorization, (if
        any) each of which is incorporated herein by reference (this MSA, the
        EULA and the Order Form (if any) shall be referred to, collectively, as
        the “Agreement”).
      </TermText>
      <TermText>
        <Bold>NOW, THEREFORE</Bold>, in consideration of the respective
        representations, warranties, covenants, and agreements set forth in the
        Agreement, and subject to Company having all necessary approvals,
        facilities, and agreements to provide the Services, Customer and Company
        agree as follows:
      </TermText>

      <TermText>
        <BoldHeading>1. Start Date</BoldHeading>
        For purposes of the Agreement, the “Insert Start Date” will be the date
        that Customer and Company agrees to each of the Order Form (If any), the
        EULA, and this MSA (as applicable) or otherwise begin use of any of the
        software or services. Beginning on the Start Date, Company will provide
        the Services to Customer, as described in the documentation of the
        Application Programming Interface (“API”) subject to both the EULA and
        the Order Form, or use. If a conflict exists between the general terms
        of this MSA and the specific terms of the Service Order, the specific
        terms of the Order Form will prevail. If a conflict exists between the
        general terms of this MSA and the specific terms of the EULA, the
        specific terms of the EULA will prevail with respect to the Software
        only.
      </TermText>
      <TermText>
        <BoldHeading>2. Taxes & Fees</BoldHeading>
        As applicable, Customer is responsible for and must pay, any and all
        applicable sales, use, excise, public utility, or other taxes,
        regulatory fees, and charges now in force or enacted in the future, by
        any federal, state, local, or other governmental body, as well as any
        other additional costs that may arise as a result of Customer's use of
        the Services, including credit card usage fees. Similarly, Company may
        pass through to Customer taxes and fees owed by the Company associated
        with Customer’s access to and use of the Services to the extent
        permissible by law. Said amounts, if any, are in addition to set-up fees
        and/or charges associated with the consumption of the Services. If
        Customer is exempt from paying any taxes or fees, Customer must provide
        documentation, acceptable to Company, certifying that Customer is
        exempt. Tax exemption will only apply from and after the date that
        Company acknowledges the Customer's exemption request. Customer agrees
        to indemnify and hold Company, its third-party provider, and their
        respective parent companies, subsidiaries, affiliated companies, as well
        as the employees, directors, officers, and shareholders of same,
        harmless from and against any and all claims, liabilities, losses,
        judgments, damages, and expenses including, without limitation,
        attorneys' fees and costs of litigation, incurred or suffered by such
        party relating to or arising out of any exemption claimed by Customer.
      </TermText>
      <TermText>
        <BoldHeading>3. Billing Adjustments</BoldHeading>
        Any request for a billing adjustment shall be made in good faith and by
        e-mail to Company at (insert email). Any such request shall include
        detailed documentation to establish the basis for any requested
        adjustment. The company will determine, in its sole, good-faith
        discretion, whether any adjustment shall be made and any such
        adjustments will be credited to the Customer’s account balance. If a
        request for a billing adjustment is not made to the e-mail address
        indicated above within seven (7) days, the charges shall be deemed
        final, valid, and binding and the Customer waives its rights to any
        credits, offsets, or adjustments with regard thereto.
      </TermText>
      <TermText>
        <BoldHeading>4. Late Payment</BoldHeading>
        The company may suspend the Provision of Services indefinitely and/or
        terminate this MSA, the EULA, and any Service Order in Company’s sole
        and absolute discretion if the payment on any invoice is not received in
        the time period specified.
      </TermText>
      <TermText>
        <BoldHeading>5. Termination</BoldHeading>
        Company shall have the right to limit, suspend and/or terminate Services
        in the event that, in Company’s reasonable determination, Customer’s use
        of the Services is: (a) materially adversely affecting Company’s
        facilities or its ability to provide services to other customers; (b)
        unlawful, unauthorized or fraudulent; or (c) otherwise in breach of this
        MSA, the EULA and/or the Service Order. In the event of any termination
        of the Agreement, Customer shall pay to Company, immediately upon
        receipt of any applicable invoice, any and all amounts due to Company
        under the Agreement.
      </TermText>
      <TermText>
        <BoldHeading>6. Responsibilities of the Parties</BoldHeading>
        <Bold>a. COMPLIANCE.</Bold> As and if applicable, Customer represents
        and warrants that it is aware of, and it and its use of the Services
        will comply in all respects with: (i) the various state and federal Do
        Not Call (“DNC”) laws, and those governing the National Do Not Call
        Registry (“NDNCR”), the Telephone Consumer Protection Act (47 USC §
        227), and its implementing regulations adopted by the Federal
        Communications Commission (47 CFR § 64.1200), as amended from
        time-to-time (“TCPA”), the Amended Telemarketing Sales Rule (“ATSR”), 16
        CFR 310 et seq., and Telephone Preference Service (“TPS”) laws and/or
        regulations; (ii) the various Canadian National Do Not Call List Rules
        (“DNCL”), Telemarketing Rules and Automatic Dialing-Announcing Device
        (“ADAD”) Rules; (iii) applicable telemarketing record keeping
        requirements; (iv) call hour/time of day restrictions (as required by
        applicable law or as directed by the called party during the course of
        the call); (v) disconnect and call abandonment requirements; (vi)
        prohibitions against contacting facilities and telephonic devices of
        certain classifications using auto dialers (or any other automatic
        telephone dialing system), artificial voice calls and/or pre-recorded
        calls without “prior express written consent” (as defined under the
        TCPA) in each instance; (vii) caller identification and consent
        requirements; (viii) live operator requirements; and (ix) opt-out and
        internal do-not-call request requirements (collectively, “Applicable
        Law”). Some information regarding the DNC, TCPA, TSR, TPS and the
        Canadian Rules (DNCL, Telemarketing Rules, and ADAD) and CTIA (carrier
        compliance and guidelines for acceptable use) can be found at:
        <br />
        <br />
        • http://www.donotcall.gov (DNC)
        <br />
        • http://www.fcc.gov (Federal Communications Commission and the TCPA)
        <br />
        • http://www.ftc.gov (Federal Trade Commission and the ATSR)
        <br />
        • http://www.tpsonline.org.uk/tps (TPS)
        <br />
        • https://www.lnnte-dncl.gc.ca/nrt-ntr-eng (DNCL, Telemarketing Rules,
        and ADAD Rules)
        <br />
        •
        https://api.ctia.org/wp-content/uploads/2019/07/190719-CTIA-Messaging-Principles-and-Best-Practices-FINAL.pdf
        <br />
        <br />
        Without limiting the foregoing, Customer represents and warrants that:
        (A) at all times prior to and after the effective date of this MSA, the
        database of consumers to be contacted utilizing the Services (“Customer
        Database”) was/shall be generated, collected, stored and used in
        compliance with Applicable Law; (B) the Customer Database consists of
        records of persons who have supplied express affirmative consent to
        receive commercial telephone calls from Customer; (C) Customer shall
        scrub the Customer Database against the NDNCR, any and all state DNC
        Registries and against its internal do-not-call list prior to calling
        any consumers in the Customer Database; and (D) the Customer Database
        shall consist of individuals that have provided “prior express written
        consent” to receive commercial telephone calls (including pre-recorded
        calls, artificial voice calls and/or auto-dialed calls) from Customer,
        within the meaning of the TCPA, to the telephone number(s) provided by
        such individuals. Customer shall retain the records of each individual’s
        “prior express written consent” (“Consent Records”) for a minimum of
        five (5) years following the creation of same, and shall provide such
        Consent Records to Company within three (3) business days of receipt of
        a Company request for same, at any time. The Consent Records shall
        include, at a minimum, the language used to obtain “prior express
        written consent,” the IP address of the source of the consumer data (or
        telemarketing voice consent capture tapes, if applicable), and the date
        and time stamp indicating the time that the consumer data was collected.
        Customer specifically consents to Company providing account-related
        information, whether or not formal legal process had been initiated, to
        any governmental or quasi-governmental agency investigation request. All
        services provided by TalkAsia VoIP LLC, including those for any TalkAsia
        VoIP LLC API applicable shall be in compliance with all local, state,
        and federal law and regulations, in addition to carrier rules and
        regulations.
      </TermText>
      <TermText>
        <BoldHeading>b. CONTENT AND SERVICES/CAMPAIGN PRODUCTS.</BoldHeading> As
        applicable, depending on the TalkAsia VoIP LLC services used, the
        Customer is and shall be solely responsible and liable for: (i) the
        creation, editorial substance, control and all other aspects of its own
        data, and third party data utilized by it in connection with the
        Services, including, but not limited to, source code, phone numbers in
        the Customer Database, programs, telemarketing scripts, databases, voice
        files and/or any other computer code (“Content”); (ii) the acts and
        omissions of any and all employees, contractors and/or agents performing
        telemarketing and other services on its behalf (“Campaign Services”);
        and (iii) the products and/or services marketed in connection with the
        Services (“Campaign Products”).
        <br />
        Message Senders should ensure that links embedded within a message do
        not conceal/obscure sender identity, cause harm or deceive. Where a
        shortened is used, Message Senders should use a shortened with a domain
        and IP address(s) dedicated to exclusive use by the Message Sender.
        <br />
        URLs contained in messages, as well as any URLs to which they redirect,
        shall be of websites that unambiguously identify the website owner
        (i.e., a person or legally registered business entity) and include
        contact information, including a physical address.
        <br />
        Messages shall not contain phone numbers that are assigned to or forward
        to unpublished phone numbers unless the owner (i.e., a person or legally
        registered business entity) of such phone numbers is unambiguously
        indicated in the text message
      </TermText>
      <TermText>
        <BoldHeading>c. NO HARMFUL CODE.</BoldHeading> Customer represents and
        warrants to Company that no Content shall be knowingly transmitted by
        Customer in connection with the Services containing any program,
        routine, or device which is designed to delete, disable, deactivate,
        interfere with or otherwise harm any software, program, data, device,
        system or service including, without limitation, any ‘time bomb,’ virus,
        drop dead device, malicious logic, worm, Trojan horse or trap or back
        door.
      </TermText>
      <TermText>
        <BoldHeading>d. INDEMNIFICATION.</BoldHeading> Customer agrees to
        indemnify, defend and hold harmless Company and its officers, directors,
        and employees from and against any losses, claims, obligations,
        liabilities, damages, settlements, costs, and expenses (including, but
        not limited to, consequential damages, incidental damages, special
        damages, disbursements, and attorneys’ fees, including attorneys’ fees
        incurred by counsel selected by Company in its sole discretion) arising
        from or relating to any actual or threatened claim (regardless of any
        fault or truth to any allegation), suit, action, proceeding,
        governmental investigation or enforcement action based upon or arising
        out of: (i) any breach of the Agreement by Customer; (ii) the Customer
        Database, Content, Campaign Products and/or Campaign Services; and/or
        (iii) any other acts or omissions of Customer.
      </TermText>
      <TermText>
        <BoldHeading>7. Intellectual Property</BoldHeading>
        <Bold>a. OWNERSHIP.</Bold> All right, title and interest, including all
        intellectual property rights and any associated hardware and software of
        Company or its licensors, and any updates, upgrades or modifications
        thereof, in and to any ideas, know-how, and/or programs developed by
        Company or its licensors (including the Services and associated
        Software) during the course of performance of the Agreement shall remain
        the property of Company or its licensors. All right, title, and interest
        in and to any Content communicated via the Company’s infrastructure
        through the use of Company Services and any applications shall remain
        the sole property of Customer and/or its customers or third-parties as
        applicable.
      </TermText>
      <TermText>
        <BoldHeading>b. RESTRICTIONS.</BoldHeading> Customer shall not: (i)
        disassemble, reverse engineer, decompile, or otherwise attempt to derive
        source code from the software or documentation, modify, adapt, create
        derivative works based upon, or translate any Services (including the
        Software or associated documentation) owned and/or provided by Company;
        or (ii) copy, install or use Services (including the Software or
        associated documentation) on any of its computer systems, servers or
        networks without Company’s prior written consent.
      </TermText>
      <TermText>
        <BoldHeading>8. Limitation of Liability</BoldHeading>
        OTHER THAN FOR INDEMNIFICATION OBLIGATIONS ARISING HEREUNDER, NEITHER
        PARTY SHALL BE LIABLE TO THE OTHER PARTY, ANY OF ITS CUSTOMERS, OR ANY
        OTHER PERSON, FIRM, OR ENTITY FOR DIRECT, INDIRECT, CONSEQUENTIAL,
        SPECIAL, INCIDENTAL, ACTUAL OR PUNITIVE DAMAGES, OR FOR ANY LOST PROFITS
        OF ANY KIND OR NATURE WHATSOEVER, UNDER ANY LEGAL OR EQUITABLE THEORY,
        EVEN IF FORESEEABLE, ARISING OUT OF ANY MISTAKE, ACCIDENT, ERROR,
        OMISSION, INTERRUPTION, OR DEFECT IN TRANSMISSION, OR DELAY ARISING OUT
        OF OR RELATING TO THE SERVICES OR THE OBLIGATIONS OF EACH PARTY PURSUANT
        TO THE AGREEMENT INCLUDING, WITHOUT LIMITATION, ANY FAILURE TO PROVIDE
        TIMELY, ACCURATE PROVISION, OR INSTALLATION OF ANY PORTION OF THE
        SERVICES, OR CONDITIONS WHICH MAY RESULT FROM ACTIONS OF REGULATORY OR
        JUDICIAL AUTHORITIES.
      </TermText>
      <TermText> 
        <BoldHeading>9. Disclaimed Warranties</BoldHeading>
        EXCEPT AS EXPRESSLY SET FORTH IN THIS MSA, THERE ARE NO OTHER
        WARRANTIES, EXPRESS OR IMPLIED HEREUNDER. THE SERVICES ARE PROVIDED ON
        AN "AS IS" AND "AS AVAILABLE" BASIS WITHOUT ANY WARRANTY OR CONDITION OF
        ANY KIND, EITHER EXPRESS OR IMPLIED. USE OF THE SERVICES IS AT THE
        CUSTOMER’S OWN RISK. THE COMPANY DOES NOT WARRANT THAT THE SERVICES WILL
        MEET CUSTOMERS’ REQUIREMENTS OR THAT THE OPERATION OF THE SERVICES WILL
        BE UNINTERRUPTED, ERROR-FREE, SECURE, ACCURATE, COMPLETE, OR CURRENT.
        WITHOUT LIMITATION, TO THE FULLEST EXTENT PERMISSIBLE BY LAW, THIS
        DISCLAIMER EXTENDS TO IMPLIED WARRANTIES AND CONDITIONS OF MERCHANTABLE
        QUALITY, FITNESS FOR A PARTICULAR PURPOSE, WARRANTIES ARISING BY STATUTE
        OR OTHERWISE IN LAW, AND/OR FROM A COURSE OF DEALING OR USAGE OF TRADE.
        COMPANY’S SOLE AND EXCLUSIVE OBLIGATION, AND CUSTOMER’S SOLE AND
        EXCLUSIVE REMEDY, FOR ANY BUG, ERROR, OR OTHER FAILURE OF THE SERVICES
        TO PERFORM AS SET FORTH IN THE AGREEMENT IS A CREDIT OR REFUND, AT
        COMPANY’S SOLE DISCRETION, BASED ON THE ORIGINAL CHARGE FOR THE
        APPLICABLE SERVICES.
      </TermText>
      <TermText>
        <BoldHeading>10. Regulations</BoldHeading>
        The Agreement is made expressly subject to all present and future valid
        orders and regulations of any regulatory body having jurisdiction of the
        subject matter of the Agreement and to the laws of the United States of
        America, any of its states, or any foreign governmental agency having
        jurisdiction. If any terms of the Agreement are found in conflict with
        any law, the Agreement shall be deemed modified to the extent necessary
        to make it comply with the law in such a way as is consistent with the
        intent and purpose of the Agreement.
      </TermText>
      <TermText>
        <BoldHeading>11. No Agency</BoldHeading>
        Neither Party is authorized to act as an agent for, nor legal
        representative of, the other Party. Neither Party has the authority to
        assume nor create any obligation on behalf of or binding on the other
        Party.
      </TermText>
      <TermText>
        <BoldHeading>12. Force Majeure</BoldHeading>
        Other than for payment obligations arising hereunder, if either Party’s
        performance under the Agreement is restricted or interfered with, in
        whole or part, by causes beyond its reasonable control including, but
        not limited to, acts of God, fire, explosion, vandalism, cable cut,
        utility curtailments, power failures, storm or another similar
        occurrence, any law, order, regulation, tariffs or rates which make it
        impossible or impractical for it or its service providers to provide its
        services at the current rates, request of the United States government,
        or of any agency, court, or other instrumentality or civil or military
        authority, or by a national emergency, insurrection, riot, war, strike,
        lockout or work stoppage or other labor difficulties, supplier failure
        or shortage or breach or delay (“Force Majeure Event”), then such Party
        shall be excused from its performance on a day-to-day basis to the
        extent of the subject Force Majeure Event. Company’s obligation to
        provide Services is subject to and contingent on the continuation of
        Company’s agreements with its underlying service providers to provide
        the applicable services to Company at the current rates and on the same
        conditions under which such service providers are currently providing or
        offering to provide the applicable services to Company. Any changes in
        or termination to those agreements will relieve the Company of its
        obligations and all liability under the Agreement.
      </TermText>
      <TermText>
        <BoldHeading>13. No Waiver</BoldHeading>
        The failure of either Party to enforce or insist upon compliance with
        any of the terms of the Agreement or the waiver of any terms contained
        within the Agreement does not constitute a general waiver or
        relinquishment of any other terms of the Agreement.
      </TermText>
      <TermText>
        <BoldHeading>14. Adjustment/Binding Effect</BoldHeading>
        The Agreement is binding upon and inures to the benefit of, the Parties
        and their respective successors and assigns. Customer will not assign,
        transfer, license or otherwise transfer all or any part of its rights,
        duties, or other interests in or to the Agreement or the proceeds from
        the Agreement ("Assignment") without Company’s prior written consent.
        Company may make an Assignment without Customer’s consent at any time
        during the term of the Agreement. Any attempt to make an Assignment in
        violation of this provision shall be null and void. Customer shall
        provide written notice to Company of any material change in its
        ownership (which is defined as a change in ownership affecting majority
        voting control of at least 50.1%). No Assignment will release either
        Party from its obligations arising under the Agreement.
      </TermText>
      <TermText>
        <BoldHeading>15. Amendment</BoldHeading>
        This MSA, the EULA, and/or the Order Form(s) may only be amended by an
        instrument in writing, signed by both parties, except in the case of
        rate or fee change notifications which do not require the Customer’s
        signature to be effective. The fees for Services may be modified WITH
        twenty-four (24) hour’s e-mail notice to the Customer. In the event of
        any dispute about the rates set forth in the Order Form and any
        amendments thereto, the rates quoted on the most recently dated
        amendment shall prevail.
      </TermText>
      <TermText>
        <BoldHeading>16. Entire Agreement</BoldHeading>
        This MSA, together with the EULA, Order Form(s) (if any) and any
        amendments/attachments hereto/thereto, supersedes and merges all prior
        agreements, promises, understandings, statements, representations,
        warranties and covenants and all inducements to the making of the
        Agreement relied on by either Party to the Agreement, whether written or
        oral and embodies the Parties' complete and entire agreement with
        respect to the subject matter of the Agreement. No statement or
        agreement, oral or written, made before the assent to this Agreement
        will vary or modify the written terms of the Agreement.
      </TermText>
      <TermText>
        <BoldHeading>17. No Third-Party Beneficiaries</BoldHeading>
        The Agreement is made solely for the benefit of Company and Customer,
        and their respective successors and permitted assigns. Nothing in the
        Agreement should be interpreted to mean that Company and Customer are
        partners, joint ventures, co-owners, or are otherwise participants in a
        common undertaking. Neither Party nor its employees are given authority,
        express or implied, to represent, act for or otherwise create or assume
        any obligation on behalf of or binding on, the other Party. Nothing in
        the Agreement will confer any rights or remedies on any third party.
      </TermText>
      <TermText>
        <BoldHeading>18. Severability</BoldHeading>
        If any terms of the Agreement are determined to be illegal,
        unenforceable, or invalid, in whole or in part, for any reason, the
        terms shall be stricken and will not affect the legality,
        enforceability, or validity of the remainder of the Agreement. If any
        terms of the Agreement are stricken as a result of this Section 18, then
        the stricken provision(s) shall be deemed replaced, to the extent
        possible, with legal, enforceable, and valid terms that are as similar
        in tenor to the stricken provision(s) as is legally permissible. All
        headings and titles contained in the Agreement are used solely to
        organize the contents of the Agreement and will not be used to affect
        the interpretation of the contents of the Agreement.
      </TermText>
      <TermText>
        <BoldHeading>19. Confidentiality</BoldHeading>
        The Services Software is deemed Confidential Information of Company.
        Customer will not disclose any use of, or information pertaining to, the
        Software to any third party without the prior written approval of
        Company. This prohibition shall include pricing or cost information.
        Customer shall maintain the confidentiality of the Software with at
        least the same degree of care that Customer uses to protect Customer’s
        own confidential and proprietary information, but not less than a
        reasonable degree of care under the circumstances. Customer will not be
        liable for the disclosure of any Confidential Information which is: (a)
        in the public domain other than by a breach of this EULA on Customer’s
        part; (b) rightfully received from a third party without any obligation
        of confidentiality; (c) rightfully known to Customer without any
        limitation on use or disclosure prior to its receipt from Company, or
        (d) generally made available to third parties by Company without
        restriction on disclosure.
        <br />
        You also acknowledge and agree that TalkAsia VoIP LLC may use your
        content internally for improving the Services and on an anonymized and
        aggregated basis for the purposes of marketing and improving the
        Services. However, TalkAsiaVoIP LLC will only share your personally
        identifiable information in accordance with TalkAsia VoIP LLC’s privacy
        policy in effect from time to time.
      </TermText>
      <TermText>
        <BoldHeading>20. Representation of Authority and Intent</BoldHeading>
        Each Party represents and warrants to the other that the assent to
        Agreement, and the performance of the Party's obligations under the
        Agreement, has been duly authorized and that the Agreement is a valid
        and legal agreement that is binding on the Parties and enforceable in
        accordance with its terms. The Parties will, at their own costs and
        expense, execute and deliver any other documents and instruments and
        will take any other actions as may be reasonably required or appropriate
        to carry out the intent and purposes of the Agreement.
      </TermText>
      <TermText>
        <BoldHeading>21. Escalation Procedures</BoldHeading>
        In the event of any disruption of Services or any problems with regard
        to Services provided by the Company, the Customer should e-mail to
        TalkAsia VoIP LLC support center (insert Email) immediately and ask that
        the applicable problem be escalated to the Company manager on duty.
      </TermText>
      <TermText>
        <BoldHeading>
          22. Governing Law and Adjudication of Disputes
        </BoldHeading>
        The Agreement is in all respects governed by the laws of the State of
        California, without regard to the choice of laws. The Parties
        specifically consent to the personal jurisdiction of the State where the
        customer resides for all disputes related to the Agreement. Any disputes
        that may arise under the Agreement shall be resolved in accordance with
        the laws of the State where customer resides and shall be adjudicated
        exclusively in the state or federal courts in the State resides.
      </TermText>
      <TermText>
        <BoldHeading>23. Counterparts</BoldHeading>
        If assent to this Agreement is by signed documents, may be signed in
        several counterparts, each of which constitutes an original, but all of
        which will constitute one instrument.
      </TermText>
      <TermText>
        <BoldHeading>24. Notices</BoldHeading>
        Unless otherwise specifically provided for herein, any notice required
        by the Agreement is effective and deemed delivered: (a) three (3)
        business days after posting with the United States Postal Service when
        mailed by certified mail, return receipt requested, properly addressed
        and with the correct postage; (b) one (1) business day after pick up by
        the courier service when sent by overnight courier, properly addressed;
        and (c) immediately when sent via facsimile, via e-mail or by opening a
        support ticket by e-mailing (insert email). The Company ticketing system
        will provide a ticket number to the Customer for reference. Notices will
        be sent to the addresses specified at the beginning of this document (if
        included) unless either Party notifies the other of an address/number
        change in writing.
      </TermText>
      <TermText>
        <BoldHeading>25. Headings</BoldHeading>
        The headings in the Agreement are for convenience only and shall not
        affect the construction hereof.
      </TermText>
      <TermText>
        <BoldHeading>26. Drafting</BoldHeading>
        Each party executing the Agreement agrees that it has fully participated
        in the drafting of the Agreement and that no Party shall be deemed to be
        the drafting Party.
      </TermText>
      <TermText>
        <BoldHeading>27. Assent to Be Bound</BoldHeading>
        Customer agrees to be bound by the terms of this MSA, and acknowledges
        that Customer is entering into a legally binding contract, by one or
        more of the following methods: (a) executing this MSA; (b) clicking to
        accept or agree where this option is made available to Customer; or (c)
        by actually using the Services. The customer should print or save a copy
        of this MSA for the Customer’s records. If Customer does not agree with
        any part of this MSA, Customer is not authorized to access or use the
        Services.
      </TermText>
      <TermText>
        <BoldHeading>28. Electronic Signatures</BoldHeading>
        Company’s authorization to provide and bill for the Services may be
        obtained by way of Customer’s electronic signature or, where applicable,
        via physical signature and/or voice affirmation, or by use of the
        services or by clicking on an applicable acceptance link on the website.
        All shall constitute an electronic signature. Once an electronic
        signature is submitted, if applicable, including the clicking of any box
        on the website indicating acceptance of these terms, : (a) this
        electronic order constitutes an electronic letter of agency; and (b)
        Customer hereby agrees to (i) the use of electronic communication in
        order to enter into this MSA, place orders and create other records
        hereunder; and (ii) the electronic delivery of notices, policies, and
        records of transactions initiated or completed through the use of the
        Services. Customer hereby waives any rights or requirements under any
        laws of electronic records, to the extent permitted under applicable
        law. The company’s reliance upon the Customer’s electronic signature was
        specifically sanctioned and written into law when the Uniform Electronic
        Transactions Act and the Electronic Signatures in Global and National
        Transactions Act were enacted in 1999 and 2000, respectively. Both laws
        specifically preempt all state laws that recognize only paper and
        handwritten signatures. 29. Attorney's Fees. Should either party bring
        any action, either in court or in the arbitration to enforce or
        adjudicate any of the terms of this Agreement, the losing party shall
        pay to the prevailing party its costs and reasonable attorney's fees.
        <br />
        <br />
        IN WITNESS WHEREOF, the customer and company have executed this
        Agreement as of the date first set forth above.
      </TermText>
      <TermText>
        <BoldHeading>TalkAsia VoIP LLC:</BoldHeading>
        Leading VoIP and Call Center Solution Services Provider
      </TermText>
      <TermText>
        <BoldHeading></BoldHeading>
      </TermText>
      <TermText>
        <BoldHeading></BoldHeading>
      </TermText>
      <TermText>
        <BoldHeading></BoldHeading>
      </TermText>
    </MainContainer>
  );
};

export default ServiceAgreement;
