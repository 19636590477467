import React, { useEffect } from "react";
import {
  Navbar,
  Hero,
  ServicesSecOne,
  ServicesWithImage,
  DialerFulfilSec,
  DialerFeatureSecThreeCol,
  LeadSec,
  Footer,
} from "../Components";

const ServicesPage = () => {
  const hero = {
    heading: "Powerful auto dialer software for your outbound calls.",
    subHeading:
      "No technical consultants or complex gadgets required. A computer, a headset, and an internet connection are all you need to start talking today.",
    image: "/assets/images/services-header-image.png",
    headingSize: "32px",
  };

  const items = [
    {
      icon: "assets/images/service-1-1.svg",
      heading: "Outbound",
      desc: "Choose Predictive, Progressive, or Manual dialing - or mix them up! Tailor your campaigns following your needs to get the most out of your leads.",
    },
    {
      icon: "assets/images/service-1-1.svg",
      heading: "Inbound",
      desc: "Receive calls with TalkAsia. Set up mixed outbound and inbound campaigns, or create campaigns exclusively for inbound use.",
    },
    {
      icon: "assets/images/service-1-1.svg",
      heading: "Transfer",
      desc: "Manage whom your agents have the possibility to forward ongoing calls to. Let them forward to specific users, campaigns, or external numbers.",
    },
  ];

  const servicesSecTwo = {
    heading: "Manage your leads effortlessly",
    desc: "Keep control of your leads throughout the process. Easy lead processing with numerous abilities to customize how you handle them.",
    // linkText: "Book a free Call",
    image: "assets/images/services-2.png",
    imageAlign: "row",
  };

  const servicesSecThree = {
    heading: "Phone numbers",
    desc: "Utilize phone numbers in your leads' local destination. Set up phone pools and switch between numbers based on time, location, or leads. Increase your reputation score and automatically re-use previous successful phone numbers.",
    // linkText: "Book a free Call",
    image: "assets/images/services-number.svg",
    imageAlign: "row-reverse",
  };

  const servicesSecFour = {
    heading: "Sell away!",
    desc: "Let agents register sales while speaking to the lead. Experience easy management of your products and ensure quality in all sales using the feedback module that lets you approve or reject sales.",
    // linkText: "Book a free Call",
    image: "assets/images/services-4.svg",
    imageAlign: "row",
  };

  const servicesSecSix = {
    heading: "Widget your way to success!",
    desc: "Experience how small features, such as calendars, Iframes, and manuscripts, can be a tremendous help in your daily work. The in-app widgets are designed to help you and your agents work smarter every day.",
    linkText: "Book a free Call",
    image: "assets/images/services-6.svg",
    imageAlign: "row-reverse",
  };


  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Navbar />
      <Hero
        heading={hero.heading}
        subHeading={hero.subHeading}
        image={hero.image}
        imageStyle={{ marginTop: "40px" }}
        headingSize={hero.headingSize}
      />
      <ServicesSecOne items={items} bubble="true" />
      <ServicesWithImage
        heading={servicesSecTwo.heading}
        desc={servicesSecTwo.desc}
        linkText={servicesSecTwo.linkText}
        image={servicesSecTwo.image}
        imageAlign={servicesSecTwo.imageAlign}
      />

      <ServicesWithImage
        heading={servicesSecThree.heading}
        desc={servicesSecThree.desc}
        linkText={servicesSecThree.linkText}
        image={servicesSecThree.image}
        imageAlign={servicesSecThree.imageAlign}
      />

      <ServicesWithImage
        heading={servicesSecFour.heading}
        desc={servicesSecFour.desc}
        linkText={servicesSecFour.linkText}
        image={servicesSecFour.image}
        imageAlign={servicesSecFour.imageAlign}
      />

      {/* <ServicesSecOne
        items={itemsSecFive}
        title="Fulfill your communication needs"
        style={{ background: "#F1FFF7" }}
        height="761px"
      /> */}

      <DialerFulfilSec />

      <ServicesWithImage
        heading={servicesSecSix.heading}
        desc={servicesSecSix.desc}
        linkText={servicesSecSix.linkText}
        image={servicesSecSix.image}
        imageAlign={servicesSecSix.imageAlign}
      />

      <DialerFeatureSecThreeCol />
      <LeadSec />

      <Footer />
    </>
  );
};

export default ServicesPage;
