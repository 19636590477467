import styled from "styled-components";

export const MainContainer = styled.div`
  min-height: 670px;
  max-width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 0 auto;

  @media screen and (max-width: 920px) {
    padding: 40px 0px;
  }
`;

export const HeadingContainer = styled.div`
  max-width: 1135px;
  width: 100%;
`;

export const PriceTableContainer = styled.div`
  max-width: 1178px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;

  @media screen and (max-width: 1080px) {
    justify-content: center;
  }
`;

export const SinglePriceTable = styled.div`
  width: 366px;
  min-height: 956px;

  border: 1px solid #f0f0f0;
  box-sizing: border-box;
  border-radius: 20px 20px 0px 0px;
  overflow: hidden;

  @media screen and (max-width: 1080px) {
    :first-child {
      margin-right: 20px;
    }
    margin-bottom: 20px;
  }

  @media screen and (max-width: 748px) {
    :first-child {
      margin-right: 0px;
    }
  }
`;

export const PriceContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const PriceHeadingContainer = styled.div`
  width: 100%;
  height: 68px;
  background: #f0f0f0;
  border-radius: 20px 20px 0px 0px;
`;

export const PriceHeading = styled.h2`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 34.9225px;
  text-align: center;
  text-transform: uppercase;
  line-height: 68px;
  letter-spacing: -0.380973px;
  color: #4d4d4d;
`;

export const NumberOfAgents = styled.h6`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 28.573px;
  line-height: 41px;
  text-align: center;
  letter-spacing: -0.380973px;
  color: #4d4d4d;
  margin-top: 44px;
`;

export const PriceImage = styled.img`
  max-width: 177px;
  margin-bottom: 40px;
`;

export const PriceFeatuesContainer = styled.div`
  height: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  margin-bottom: 68px;
`;

export const SingleFeature = styled.p`
  width: 207.95px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: normal;
  font-size: 15.8739px;
  color: #000000;
  text-align: center;
`;

export const GetStarted = styled.button`
  width: 230.17px;
  height: 68.26px;

  background: linear-gradient(92.97deg, #fdd305 -5.53%, #fcb017 125.9%);
  border: none;
  border-radius: 30px 0px 0px 0px;

  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 21px;
  color: #ffffff;
  cursor: pointer;
  margin-bottom: 40px;
`;

export const Bold = styled.span`
  font-weight: bold;
`;

// export const MainContainer = styled.div``;
// export const MainContainer = styled.div``;
// export const MainContainer = styled.div``;
