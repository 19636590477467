import React, { useState } from "react";
import { useMediaQuery } from "react-responsive";
import {
  MainContainer,
  LogoContainer,
  MainNavContainer,
  ChatContainer,
  NavItems,
  NavItem,
  SingleNavLink,
  Logo,
  Button,
  Icon,
  Text,
  MobMenu,
  MobMenuClose,
  MobMenuContainer,
  Home,
  MobMenuLinksCont,
  DropDown,
  TopArrow,
  DropItem,
  DropImg,
  DropInfo,
  DropHeading,
  DropSubHeading,
} from "./Navbar.elements";

const Navbar = ({ color }) => {
  const isMob = useMediaQuery({
    query: "(max-device-width: 690px)",
  });

  const [isOpen, setIsOpen] = useState(false);
  const [showDropDown, setShowDropDown] = useState(false);

  const isOpenHandle = () => {
    setIsOpen(!isOpen);
  };

  const showDropDownHandle = () => {
    setShowDropDown(!showDropDown);
  };

  return (
    <>
      {isOpen ? (
        <MobMenuContainer>
          <MobMenuClose onClick={isOpenHandle} />
          <MobMenuLinksCont>
            <NavItem>
              <SingleNavLink
                color={color}
                to="/"
                onClick={() => isOpenHandle()}
              >
                Home
              </SingleNavLink>
            </NavItem>

            <NavItem>
              <SingleNavLink
                color={color}
                to="/services"
                onClick={() => isOpenHandle()}
              >
                Product
              </SingleNavLink>
            </NavItem>

            <NavItem>
              <SingleNavLink
                color={color}
                to="/about-us"
                onClick={() => isOpenHandle()}
              >
                About Us
              </SingleNavLink>
            </NavItem>

            <NavItem>
              <SingleNavLink
                color={color}
                to="/pricing"
                onClick={() => isOpenHandle()}
              >
                Pricing
              </SingleNavLink>
            </NavItem>

            <NavItem>
              <SingleNavLink
                color={color}
                to="/download"
                onClick={() => isOpenHandle()}
              >
                Downloads
              </SingleNavLink>
            </NavItem>

            <NavItem>
              <SingleNavLink
                color={color}
                to="/contact-us"
                onClick={() => isOpenHandle()}
              >
                Contact Us
              </SingleNavLink>
            </NavItem>
          </MobMenuLinksCont>
        </MobMenuContainer>
      ) : (
        ""
      )}
      <MainContainer>
        <LogoContainer>
          <Home to="/">
            <Logo src="/assets/images/logo.svg" alt="logo" />
          </Home>
        </LogoContainer>
        <MainNavContainer>
          <NavItems>
            <NavItem>
              <SingleNavLink
                to="/#"
                color={color}
                onMouseOver={showDropDownHandle}
              >
                Product
              </SingleNavLink>
              {showDropDown ? (
                <DropDown onMouseLeave={showDropDownHandle}>
                  <TopArrow></TopArrow>

                  <DropItem to="/dialer">
                    <DropImg src="assets/images/drop-1.svg" />
                    <DropInfo>
                      <DropHeading>Hosted Dialer</DropHeading>
                      <DropSubHeading>
                        Leads Generation without Hassle
                      </DropSubHeading>
                    </DropInfo>
                  </DropItem>

                  <DropItem to="/voip">
                    <DropImg src="assets/images/drop-2.svg" />
                    <DropInfo>
                      <DropHeading>US/Canada Termination</DropHeading>
                      <DropSubHeading>Calling without limits</DropSubHeading>
                    </DropInfo>
                  </DropItem>

                  <DropItem to="/switch">
                    <DropImg src="assets/images/drop-3.svg" />
                    <DropInfo>
                      <DropHeading>Hosted SoftSwitch</DropHeading>
                      <DropSubHeading>
                        Build you VoIP Infrastructure
                      </DropSubHeading>
                    </DropInfo>
                  </DropItem>

                  {/* <DropItem to="/amazon">
                    <DropImg src="assets/images/drop-4.svg" />
                    <DropInfo>
                      <DropHeading>Amazon Services</DropHeading>
                      <DropSubHeading>Optimize your amz brand</DropSubHeading>
                    </DropInfo>
                  </DropItem> */}
                </DropDown>
              ) : (
                ""
              )}
            </NavItem>

            <NavItem>
              <SingleNavLink color={color} to="/about-us">
                About Us
              </SingleNavLink>
            </NavItem>

            <NavItem>
              <SingleNavLink color={color} to="/pricing">
                Pricing
              </SingleNavLink>
            </NavItem>

            <NavItem>
              <SingleNavLink color={color} to="/download">
                Downloads
              </SingleNavLink>
            </NavItem>

            <NavItem>
              <SingleNavLink color={color} to="/contact-us">
                Contact Us
              </SingleNavLink>
            </NavItem>
          </NavItems>
        </MainNavContainer>
        <ChatContainer>
          {isMob ? (
            isOpen ? (
              <MobMenuClose onClick={isOpenHandle} />
            ) : (
              <MobMenu onClick={isOpenHandle} />
            )
          ) : (
            <Button to="/contact-us">
              <Icon src="/assets/images/chat.svg" />
              <Text>Let’s Talk</Text>
            </Button>
          )}
        </ChatContainer>
      </MainContainer>
    </>
  );
};

export default Navbar;
