import React, { useState } from "react";
import {
  MainContainer,
  HeadingCont,
  Heading,
  SubHeading,
  ContentCont,
  MenuCont,
  UL,
  ListItem,
  DetailsCont,
  DetHeading,
  DetDesc,
  CheckPointsCont,
  SingleCheckPoint,
  CheckIcon,
  CheckHeading,
  ButtonCont,
  GetStart,
  BubbleRight,
} from "./FeatureSecTwo.elements";

const FeatureSecTwo = () => {
  const [desc, setDesc] = useState(1);

  const changeContent = (x) => {
    setDesc(x);
  };
  return (
    <>
      <MainContainer>
        <HeadingCont>
          <Heading>Key features</Heading>
          <SubHeading>
            Numerous features make it possible to customize the system in
            accordance with all your needs.
          </SubHeading>
        </HeadingCont>

        <ContentCont>
          <MenuCont>
            <UL>
              <ListItem
                onClick={() => changeContent(1)}
                style={{ color: desc === 1 ? "#36a469" : "" }}
              >
                Campaigns
              </ListItem>
              <ListItem
                onClick={() => changeContent(2)}
                style={{ color: desc === 2 ? "#36a469" : "" }}
              >
                Dialing Modes
              </ListItem>
              <ListItem
                onClick={() => changeContent(3)}
                style={{ color: desc === 3 ? "#36a469" : "" }}
              >
                Analytics
              </ListItem>
              <ListItem
                onClick={() => changeContent(4)}
                style={{ color: desc === 4 ? "#36a469" : "" }}
              >
                Lead management
              </ListItem>
              <ListItem
                onClick={() => changeContent(5)}
                style={{ color: desc === 5 ? "#36a469" : "" }}
              >
                Hardware
              </ListItem>
            </UL>
          </MenuCont>
          <DetailsCont>
            {(() => {
              switch (desc) {
                case 1:
                  return (
                    <>
                      <DetHeading>Spotlessly optimized call flows</DetHeading>
                      <DetDesc>
                        Intelligent campaigns optimize your call center
                        efficiently by automating contacts & call flows, making
                        sure that high-opportunity conversations are the only
                        ones you will be having.
                      </DetDesc>
                      <CheckPointsCont>
                        <SingleCheckPoint width="100%">
                          <CheckIcon src="assets/images/check-icon.svg" />
                          <CheckHeading>Flexible call strategy</CheckHeading>
                        </SingleCheckPoint>

                        <SingleCheckPoint width="100%">
                          <CheckIcon src="assets/images/check-icon.svg" />
                          <CheckHeading>Predictive dialing</CheckHeading>
                        </SingleCheckPoint>

                        <SingleCheckPoint width="100%">
                          <CheckIcon src="assets/images/check-icon.svg" />
                          <CheckHeading>Inbound return calls</CheckHeading>
                        </SingleCheckPoint>

                        <SingleCheckPoint width="100%">
                          <CheckIcon src="assets/images/check-icon.svg" />
                          <CheckHeading>Remember all callbacks</CheckHeading>
                        </SingleCheckPoint>

                        <SingleCheckPoint width="100%">
                          <CheckIcon src="assets/images/check-icon.svg" />
                          <CheckHeading>
                            Complete, yet simple lead info
                          </CheckHeading>
                        </SingleCheckPoint>

                        <SingleCheckPoint width="100%">
                          <CheckIcon src="assets/images/check-icon.svg" />
                          <CheckHeading>Compliant call recording</CheckHeading>
                        </SingleCheckPoint>
                      </CheckPointsCont>

                      <ButtonCont>
                        <GetStart
                          href="https://register.talkasiavoip.com/"
                          target="_blank"
                        >
                          Get Start Now
                        </GetStart>
                      </ButtonCont>
                    </>
                  );
                 

                case 2:
                  return (
                    <>
                      <DetHeading>Multiple dialing modes</DetHeading>
                      <DetDesc>
                        TalkAsia provides you with powerful tools to suit your
                        workflow, including multiple dialing modes. Increase
                        contact rates with dialing strategies and powerful
                        tools.
                      </DetDesc>
                      <CheckPointsCont>
                        <SingleCheckPoint width="100%">
                          <CheckIcon src="assets/images/check-icon.svg" />
                          <CheckHeading>
                            Predictive dialing automatically adjust dial speed
                          </CheckHeading>
                        </SingleCheckPoint>

                        <SingleCheckPoint width="100%">
                          <CheckIcon src="assets/images/check-icon.svg" />
                          <CheckHeading>
                            Progressive dialing allows time before the call is
                            automatically dialed
                          </CheckHeading>
                        </SingleCheckPoint>

                        <SingleCheckPoint width="100%">
                          <CheckIcon src="assets/images/check-icon.svg" />
                          <CheckHeading>
                            Power dialing automates dialing so that your agents
                            are always on live calls
                          </CheckHeading>
                        </SingleCheckPoint>

                        <SingleCheckPoint width="100%">
                          <CheckIcon src="assets/images/check-icon.svg" />
                          <CheckHeading>
                            Preview dialing allow time to review lead before
                            call for a more informed conversation.
                          </CheckHeading>
                        </SingleCheckPoint>

                        <SingleCheckPoint width="100%">
                          <CheckIcon src="assets/images/check-icon.svg" />
                          <CheckHeading>
                            Configure dialing modes to suit your needs.
                          </CheckHeading>
                        </SingleCheckPoint>

                        <SingleCheckPoint width="100%">
                          <CheckIcon src="assets/images/check-icon.svg" />
                          <CheckHeading>
                            Maximize efficiency & reduce agent wait time.
                          </CheckHeading>
                        </SingleCheckPoint>
                      </CheckPointsCont>

                      <ButtonCont>
                        <GetStart
                          href="https://register.talkasiavoip.com/"
                          target="_blank"
                        >
                          Get Start Now
                        </GetStart>
                      </ButtonCont>
                    </>
                  );
                 

                case 3:
                  return (
                    <>
                      <DetHeading>All activities at your fingertips</DetHeading>
                      <DetDesc>
                        Keep track of your activities across departments,
                        projects, and campaigns. TalkAsia's flexible reporting
                        tools show only the KPIs that are relevant to your
                        business.
                      </DetDesc>
                      <CheckPointsCont>
                        <SingleCheckPoint width="100%">
                          <CheckIcon src="assets/images/check-icon.svg" />
                          <CheckHeading>Easy access to all KPIs</CheckHeading>
                        </SingleCheckPoint>

                        <SingleCheckPoint width="100%">
                          <CheckIcon src="assets/images/check-icon.svg" />
                          <CheckHeading>Full data history</CheckHeading>
                        </SingleCheckPoint>

                        <SingleCheckPoint width="100%">
                          <CheckIcon src="assets/images/check-icon.svg" />
                          <CheckHeading>
                            Automatic & consistent reporting
                          </CheckHeading>
                        </SingleCheckPoint>

                        <SingleCheckPoint width="100%">
                          <CheckIcon src="assets/images/check-icon.svg" />
                          <CheckHeading>
                            Follow live agent activity
                          </CheckHeading>
                        </SingleCheckPoint>

                        <SingleCheckPoint width="100%">
                          <CheckIcon src="assets/images/check-icon.svg" />
                          <CheckHeading>Live campaign performance</CheckHeading>
                        </SingleCheckPoint>

                        <SingleCheckPoint width="100%">
                          <CheckIcon src="assets/images/check-icon.svg" />
                          <CheckHeading>Listen to active calls</CheckHeading>
                        </SingleCheckPoint>
                      </CheckPointsCont>

                      <ButtonCont>
                        <GetStart
                          href="https://register.talkasiavoip.com/"
                          target="_blank"
                        >
                          Get Start Now
                        </GetStart>
                      </ButtonCont>
                    </>
                  );
                  

                case 4:
                  return (
                    <>
                      <DetHeading>Utilize contacts compliantly</DetHeading>
                      <DetDesc>
                        Keep your leads under control throughout the process.
                        Easy and manageable lead processing with numerous
                        abilities to customize how you handle them.
                      </DetDesc>
                      <CheckPointsCont>
                        <SingleCheckPoint width="100%">
                          <CheckIcon src="assets/images/check-icon.svg" />
                          <CheckHeading>
                            Import & export from all formats
                          </CheckHeading>
                        </SingleCheckPoint>

                        {/* <SingleCheckPoint width="100%">
                          <CheckIcon src="assets/images/check-icon.svg" />
                          <CheckHeading>
                            Automatic & frequent exports by mail
                          </CheckHeading>
                        </SingleCheckPoint> */}

                        <SingleCheckPoint width="100%">
                          <CheckIcon src="assets/images/check-icon.svg" />
                          <CheckHeading>Make repeatable tasks</CheckHeading>
                        </SingleCheckPoint>

                        <SingleCheckPoint width="100%">
                          <CheckIcon src="assets/images/check-icon.svg" />
                          <CheckHeading>
                            Create unlimited data fields
                          </CheckHeading>
                        </SingleCheckPoint>

                        <SingleCheckPoint width="100%">
                          <CheckIcon src="assets/images/check-icon.svg" />
                          <CheckHeading>Edit & enrich lead data</CheckHeading>
                        </SingleCheckPoint>

                        {/* <SingleCheckPoint width="100%">
                          <CheckIcon src="assets/images/check-icon.svg" />
                          <CheckHeading>
                            Set up automatic data deletion processes
                          </CheckHeading>
                        </SingleCheckPoint> */}

                        <SingleCheckPoint width="100%">
                          <CheckIcon src="assets/images/check-icon.svg" />
                          <CheckHeading>Rich segmentation tools</CheckHeading>
                        </SingleCheckPoint>

                        <SingleCheckPoint width="100%">
                          <CheckIcon src="assets/images/check-icon.svg" />
                          <CheckHeading>
                            Use same leads for multiple campaigns
                          </CheckHeading>
                        </SingleCheckPoint>
                      </CheckPointsCont>

                      <ButtonCont>
                        <GetStart
                          href="https://register.talkasiavoip.com/"
                          target="_blank"
                        >
                          Get Start Now
                        </GetStart>
                      </ButtonCont>
                    </>
                  );
                  

                case 5:
                  return (
                    <>
                      <DetHeading>
                        State-of-the-art hardware for your project.
                      </DetHeading>
                      <DetDesc>
                        A combination of stable technology, professional support
                        and flexible services enable you to continuously
                        strengthen your market position.
                      </DetDesc>
                      <CheckPointsCont>
                        <SingleCheckPoint width="100%">
                          <CheckIcon src="assets/images/check-icon.svg" />
                          <CheckHeading>
                            Handle even your most resource-intensive projects
                            with ease.
                          </CheckHeading>
                        </SingleCheckPoint>

                        <SingleCheckPoint width="100%">
                          <CheckIcon src="assets/images/check-icon.svg" />
                          <CheckHeading>
                            Qualified support available to assist you.
                          </CheckHeading>
                        </SingleCheckPoint>

                        <SingleCheckPoint width="100%">
                          <CheckIcon src="assets/images/check-icon.svg" />
                          <CheckHeading>Firewall protected.</CheckHeading>
                        </SingleCheckPoint>

                        <SingleCheckPoint>
                          <CheckIcon src="assets/images/check-icon.svg" />
                          <CheckHeading>Lowest Latency</CheckHeading>
                        </SingleCheckPoint>

                        <SingleCheckPoint width="100%">
                          <CheckIcon src="assets/images/check-icon.svg" />
                          <CheckHeading>
                            Latest hardware appliances
                          </CheckHeading>
                        </SingleCheckPoint>

                        <SingleCheckPoint width="100%">
                          <CheckIcon src="assets/images/check-icon.svg" />
                          <CheckHeading>
                            Comprehensive protection from DDoS attackers
                          </CheckHeading>
                        </SingleCheckPoint>
                      </CheckPointsCont>

                      <ButtonCont>
                        <GetStart
                          href="https://register.talkasiavoip.com/"
                          target="_blank"
                        >
                          Get Start Now
                        </GetStart>
                      </ButtonCont>
                    </>
                  );
                  

                default:
                  return null;
              }
            })()}
          </DetailsCont>
        </ContentCont>
        <BubbleRight src="assets/images/feature-2-bubble.svg" />
      </MainContainer>
    </>
  );
};

export default FeatureSecTwo;
