import styled from "styled-components";

export const MainContainer = styled.div`
  min-height: 820px;
  max-width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 0 auto;
  margin: 40px 0px;

  @media screen and (max-width: 690px) {
  }
`;

export const Form = styled.form`
  max-width: 576px;
  width: 100%;
  height: 804px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 88px;
  background: #ffffff;
  box-shadow: 0px 0px 94px 10px rgba(187, 187, 187, 0.1);
`;

export const FieldCont = styled.div`
  max-width: 428px;
  width: 100%;
  margin-bottom: 32px;
`;

export const InputCont = styled.div`
  max-width: 428px;
  width: 100%;
  position: relative;

  @media screen and (max-width: 620px) {
    width: 80%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
  }
`;

export const VerifyCont = styled.div`
  max-width: 428px;
  width: 100%;
  position: relative;
  /* display: flex;
  align-items: center; */

  @media screen and (max-width: 620px) {
    width: 80%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
  }
`;

export const VerifyContBottom = styled.div`
  max-width: 428px;
  width: 100%;
display:flex;

  @media screen and (max-width: 620px) {
    width: 80%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
`;

export const InputField = styled.input`
  max-width: 428px;
  height: 50px;
  width: 100%;
  border: none;
  border-bottom: 1px solid #c0c0c0;
  font-family: "Poppins";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #787878;
  outline: none;

  @media screen and (max-width: 620px) {
    width: 100%;
  }

  &::placeholder {
    position: absolute;
    top: 0px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: #787878;
  }

  &:focus {
    &::placeholder {
      color: transparent;
    }
  }
`;

export const InputTextArea = styled.textarea`
 resize:"none";
  max-width: 428px;
  height: 140px;
  width: 100%;
 ;
  border: none;
  border-bottom: 1px solid #c0c0c0;
  font-family: "Poppins";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #787878;
  outline: none;

  @media screen and (max-width: 620px) {
    width: 100%;
  }

  &::placeholder {
    position: absolute;
    top: 0px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: #787878;
  }

  &:focus {
    &::placeholder {
      color: transparent;
    }
  }
`;

export const Icon = styled.img`
  width: 20.78px;
  position: absolute;
  bottom: 21px;
  right: 0px;

  @media screen and (max-width: 620px) {
  }
`;

export const SubmitCont = styled.div`
  max-width: 428px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  @media screen and (max-width: 620px) {
    width: 80%;
    justify-content: center;
  }
`;

export const SubmitButton = styled.button`
  width: 120px;
  height: 42px;
  background: #262626;
  border-radius: 42px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  color: #ffffff;
  text-align: center;
  cursor: pointer;
  border: none;
  outline: none;
  margin-top: 30px;
`;

export const Error = styled.span`
  max-width: 428px;
  width: 100%;
  margin: 0 auto;
  color: #b71c1c;
`;

export const SendCode = styled.button`
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #FF369C;
  font-family: 'Poppins';
font-style: normal;
font-weight: 500;
font-size: 18px;
color: #FFFFFF;
  line-height: 50px;
  border: none;
  cursor: pointer;

  @media screen and (max-width: 620px) {

    font-size: 14px;
  }
`;

export const IconSendBtn = styled.img`
  width: 20.78px;
  margin-right: 7px;
  -webkit-animation:spin 4s linear infinite;
    -moz-animation:spin 4s linear infinite;
    animation:spin 4s linear infinite;



  @-moz-keyframes spin { 
    100% { -moz-transform: rotate(360deg); } 
}
@-webkit-keyframes spin { 
    100% { -webkit-transform: rotate(360deg); } 
}
@keyframes spin { 
    100% { 
        -webkit-transform: rotate(360deg); 
        transform:rotate(360deg); 
    } 

  @media screen and (max-width: 620px) {
    display: none;
  }
`;

export const VerifyCode = styled.button`
width: 160px;
height: 50px;
background: #FF369C;
border:none;
font-family: 'Poppins';
font-style: normal;
font-weight: 500;
font-size: 18px;
color: #FFFFFF;
cursor:pointer;

`;

export const InputFieldVerification = styled.input`
width:90%;
height:50px;
outline:none;
border: 1px solid #FF369C;
font-family: 'Poppins';
font-style: normal;
font-weight: normal;
font-size: 18px;
color: #CCCCCC;
padding:0px 10px;

::placeholder{
font-family: 'Poppins';
font-style: normal;
font-weight: normal;
font-size: 18px;
color: #CCCCCC;
}
`;

// export const MainContainer = styled.div``;
// export const MainContainer = styled.div``;
// export const MainContainer = styled.div``;