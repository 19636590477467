import styled from "styled-components";

export const MainContainer = styled.div`
  min-height: 820px;
  max-width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 0 auto;
  margin: 40px 0px;

  @media screen and (max-width: 640px) {
    padding: 40px 0px;
  }
`;

export const Form = styled.form`
  max-width: 676px;
  width: 100%;
  min-height: 804px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 88px;
  background: #ffffff;
  box-shadow: 0px 0px 94px 10px rgba(187, 187, 187, 0.1);
  padding: 40px 0px;
`;

export const InputCont = styled.div`
  max-width: 528px;
  width: 100%;
  position: relative;
  margin-bottom: 32px;

  @media screen and (max-width: 620px) {
    width: 80%;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
`;

export const InputField = styled.input`
  max-width: 528px;
  height: 50px;
  width: 100%;
  border: none;
  border-bottom: 1px solid #c0c0c0;
  font-family: "Poppins";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #787878;
  outline: none;

  @media screen and (max-width: 620px) {
    width: 100%;
  }

  &::placeholder {
    position: absolute;
    top: 0px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: #787878;
  }

  &:focus {
    &::placeholder {
      color: transparent;
    }
  }
`;

export const TwoCol = styled.div`
  max-width: 528px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  ${InputField} {
    width: 90%;
  }

  @media screen and (max-width: 620px) {
    flex-direction: column;

    ${InputField} {
      width: 100%;
    }
  }
`;

export const InputTextArea = styled.textarea`
  max-width: 528px;
  height: 160px;
  width: 100%;
  border: none;
  border-bottom: 1px solid #c0c0c0;
  font-family: "Poppins";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #787878;
  outline: none;

  @media screen and (max-width: 620px) {
    width: 100%;
  }

  &::placeholder {
    position: absolute;
    top: 0px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: #787878;
  }

  &:focus {
    &::placeholder {
      color: transparent;
    }
  }
`;

export const Icon = styled.img`
  position: absolute;
  bottom: 21px;
  right: 0px;

  @media screen and (max-width: 620px) {
  }
`;

export const SubmitCont = styled.div`
  max-width: 528px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  @media screen and (max-width: 620px) {
    width: 80%;
    justify-content: center;
  }
`;

export const SubmitButton = styled.button`
  width: 160px;
  height: 55px;
  background: #262626;
  border-radius: 42px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  color: #ffffff;
  text-align: center;
  cursor: pointer;
  border: none;
  outline: none;
  margin-top: 30px;
`;

export const CheckTitle = styled.h4`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #545454;
  text-align: left;
  margin-bottom: 15px;
`;

export const CheckBox = styled.input.attrs({ type: "checkbox" })`
  visibility: hidden;
`;

export const CheckLable = styled.p`
  font-family: "Poppins";
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  color: #1d7342;
`;

export const CheckCont = styled.div``;

export const CheckLabelMain = styled.label`
  display: flex;
`;

export const CheckMainCont = styled.div`
  width: 74%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;

  @media screen and (max-width: 640px) {
    /* flex-direction: column; */
  }
`;

export const FileUpload = styled.input.attrs({ type: "file" })`
  background: red;
  max-width: 528px;
  height: 81px;
  width: 100%;
  border: none;
  border-bottom: 1px solid #c0c0c0;
  font-family: "Poppins";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #787878;
  outline: none;

  @media screen and (max-width: 620px) {
    width: 100%;
  }
`;

export const Price = styled.h4`
  font-family: "Poppins";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #36a469;
  outline: none;
  margin-bottom: 10px;
`;

export const Paypal = styled.div``;

export const SubButton = styled.button`
  width: 120px;
  height: 42px;
  background: #262626;
  border-radius: 42px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  color: #ffffff;
  text-align: center;
  cursor: pointer;
  border: none;
  outline: none;
  margin-top: 30px;
`;

export const Policy = styled.h4`
  align-self: flex-start;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  color: #424242;
  margin-bottom: 60px;
`;
