import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
*{
    box-sizing:border-box;
    font-family: 'Poppins', sans-serif;
    margin:0;
    padding:0;
}
`;

export default GlobalStyle;
