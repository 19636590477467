import styled from "styled-components";

export const MainContainer = styled.div`
  min-height: 750px;
  /* max-width: 1440px; */
  max-width: 100%;
  width: 100%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #36a469;
  position: relative;

  @media screen and (max-width: 960px) {
    flex-direction: column;
    padding: 40px 10px;
  }
`;

export const InfoCont = styled.div`
  margin-right: 151px;
  @media screen and (max-width: 960px) {
    margin-right: 0px;
    text-align: center;
  }
`;

export const Heading = styled.h2`
  max-width: 394px;
  width: 100%;
  font-family: "Poppins";
  font-style: normal;
  font-weight: bold;
  font-size: 44px;
  line-height: 56px;
  color: #ffffff;
  margin-bottom: 2px;

  @media screen and (max-width: 960px) {
    max-width: 100%;
    margin-bottom: 20px;
  }
`;

export const SubHeading = styled.p`
  max-width: 389px;
  width: 100%;
  font-family: "Poppins";
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 26px;
  color: #eeeeee;

  @media screen and (max-width: 960px) {
    max-width: 100%;
  }
`;

export const BoxCont = styled.div`
  min-height: 750px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media screen and (max-width: 480px) {
    flex-direction: column;
    margin: 20px 0px;
  }
`;

export const BoxSecOne = styled.div`
  margin-right: 30px;

  @media screen and (max-width: 480px) {
    margin-right: 0px;
  }
`;

export const BoxSecTwo = styled.div`
  min-height: 750px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
`;

export const SingleBox = styled.div`
  max-width: 258px;
  width: 100%;
  height: 303px;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  box-shadow: 0px 25px 24px rgba(16, 98, 54, 0.1);
  padding: 0px 28px;
`;

export const BoxHeading = styled.h4`
  max-width: 203px;
  width: 100%;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 40px;

  text-align: left;
  color: #262626;
  margin-bottom: 8px;
`;

export const BoxDesc = styled.p`
  max-width: 170px;
  width: 100%;
  font-family: "Poppins";
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 26px;
  color: #959595;
  text-align: left;
`;

export const Bubble = styled.img`
  position: absolute;
  bottom: -60px;

  @media screen and (max-width: 960px) {
    display: none;
  }
`;

// export const Desc =styled.div``;
// export const Desc =styled.div``;
