import styled from "styled-components";

export const MainContainer = styled.div`
  min-height: 264px;
  /* max-width: 1440px; */
  max-width: 100%;
  width: 100%;
  margin: 0 auto;
  display: flex;
  position: relative;
  @media screen and (max-width: 1000px) {
    padding: 0px 10px;
  }
`;

export const LogoContainer = styled.div`
  max-width: 764px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;

  @media screen and (max-width: 680px) {
    justify-content: center;
  }
`;

export const Logo = styled.img`
  @media screen and (max-width: 680px) {
    margin-right: 20px;
  }
`;
export const Bubble = styled.img`
  position: absolute;

  @media screen and (max-width: 1000px) {
    display: none;
  }
`;
// export const Desc =styled.div``;
// export const Desc =styled.div``;
// export const Desc =styled.div``;
