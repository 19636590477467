import styled from "styled-components";

export const MainContainer = styled.div`
  min-height: 603px;
  max-width: 1440px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 0 auto;
  position: relative;

  @media screen and (max-width: 800px) {
    padding: 40px 26px;
  }
`;

export const TextCont = styled.div`
  max-width: 802px;
  width: 100%;
`;

export const Heading = styled.h2`
  font-family: "Poppins";
  font-style: normal;
  font-weight: bold;
  font-size: 44px;
  line-height: 56px;
  color: #262626;
  text-align: center;
  margin-bottom: 38.4px;
`;

export const Text = styled.p`
  font-family: "Poppins";
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 26px;
  color: #787878;
`;

// export const Heading =styled.div``;
