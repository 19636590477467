import styled from "styled-components";

export const MainContainer = styled.div`
  min-height: 680px;
  max-width: 1440px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 980px) {
  }
`;

export const HeadingCont = styled.div`
  max-width: 1137px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 83px;
`;

export const Heading = styled.h2`
  max-width: 748px;
  width: 100%;
  font-family: "Poppins";
  font-style: normal;
  font-weight: bold;
  font-size: 44px;
  color: #262626;
  margin-bottom: 26px;

  @media screen and (max-width: 720px) {
    text-align: center;
    margin-top: 40px;
  }
`;

export const SubHeading = styled.h4`
  max-width: 621px;
  width: 100%;
  font-family: "Poppins";
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  color: #383838;
  line-height: 26px;
  text-align: center;
`;

export const FeatureCont = styled.div`
  max-width: 1137px;
  width: 100%;
  min-height: 274px;
  height: 100%;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;

  @media screen and (max-width: 1060px) {
    justify-content: space-evenly;
  }
`;

export const SingleFeature = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  max-height: 274px;

  @media screen and (max-width: 1060px) {
    margin-bottom: 40px;
  }

  @media screen and (max-width: 480px) {
    align-items: flex-start;
  }
`;

export const Icon = styled.img`
  margin-bottom: 27px;
`;

export const Title = styled.h4`
  max-width: 267px;
  width: 100%;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 25px;
  line-height: 40px;
  text-align: center;
  color: #262626;
  margin-bottom: 27px;

  @media screen and (max-width: 480px) {
    text-align: left;
  }
`;

export const Desc = styled.p`
  max-width: 233px;
  width: 100%;
  font-family: "Poppins";
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 26px;
  text-align: center;
  color: #383838;

  @media screen and (max-width: 480px) {
    text-align: left;
  }
`;

// export const Desc =styled.div``;
// export const Desc =styled.div``;
// export const Desc =styled.div``;
// export const Desc =styled.div``;
