import React, { useState, useEffect } from "react";
import {
  MainContainer,
  Form,
  InputCont,
  InputField,
  TwoCol,
  SubmitButton,
  SubmitCont,
  CheckTitle,
  CheckCont,
  CheckLable,
  CheckLabelMain,
  CheckMainCont,
  InfoTitle,
  InfoDesc,
  MainTitle,
  SubTitle,
} from "./AbuseReportForm.elements";

import axios from "axios";
import Checkbox from "./Checkbox";

const AbuseReportForm = () => {
  const [showForm, setShowForm] = useState(true);
  const [formData, setFormData] = useState({
    form: "abuse",
    subject: "",
    phone: "",
    phone_numbers_abused_from: "",
    abuse_date: "",
    abuse_time: "",
    service_received_abuse: "",
    service_received_abuse_1: "",
    name: "",
    email: "",
    additional_details: "",
  });

  const submitForm = async (e) => {
    e.preventDefault();
    const response = await axios.post(
      "https://register.talkasiavoip.com/public/api/forms",
      {
        data: formData,
      }
    );
    setShowForm(false);
    console.log(response);
  };


  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      {console.log(formData)}
      <MainContainer>
        {showForm ? (
          <Form>
            <InputCont>
              <MainTitle>Abuse report details</MainTitle>
              <SubTitle>All report detail fields are required.</SubTitle>
            </InputCont>

            <InputCont>
              <InputField
                type="text"
                placeholder="Subject"
                onChange={(e) =>
                  setFormData((prev) => ({
                    ...prev,
                    subject: e.target.value,
                  }))
                }
              />
              {/* <Icon src="assets/images/contact-input-icon-1.svg" /> */}
            </InputCont>
            <InputCont>
              <InputField
                type="number"
                placeholder="Phone Number"
                onChange={(e) =>
                  setFormData((prev) => ({
                    ...prev,
                    number: e.target.value,
                  }))
                }
              />
            </InputCont>

            <InputCont>
              <InputField
                type="number"
                placeholder="Phone Number Received Abuse"
                onChange={(e) =>
                  setFormData((prev) => ({
                    ...prev,
                    phone_numbers_abused_from: e.target.value,
                  }))
                }
              />
            </InputCont>

            <TwoCol>
              <InputCont>
                <InputField
                  type="date"
                  placeholder="Date of Abuse"
                  onChange={(e) =>
                    setFormData((prev) => ({
                      ...prev,
                      abuse_date: e.target.value,
                    }))
                  }
                />
              </InputCont>

              <InputCont>
                <InputField
                  type="time"
                  placeholder="Time of Abuse"
                  onChange={(e) =>
                    setFormData((prev) => ({
                      ...prev,
                      abuse_time: e.target.value,
                    }))
                  }
                />
              </InputCont>
            </TwoCol>

            <InputCont>
              <CheckTitle>Services Receiving Abuse</CheckTitle>

              <CheckMainCont>
                <CheckCont>
                  <CheckLabelMain>
                    <Checkbox
                      checked={formData.service_received_abuse}
                      value="voice"
                      name="voice"
                      onChange={(e) =>
                        setFormData((prev) => ({
                          ...prev,
                          service_received_abuse: e.target.value,
                        }))
                      }
                    />
                    <CheckLable style={{ marginLeft: 8 }}>Voice</CheckLable>
                  </CheckLabelMain>
                </CheckCont>
              </CheckMainCont>
            </InputCont>

            <InputCont>
              <InfoTitle>Tell us about yourself</InfoTitle>
              <InfoDesc>
                The following information is not required and will only be used
                to keep you informed on the report.
              </InfoDesc>
            </InputCont>

            <InputCont>
              <InputField
                type="text"
                placeholder="Full Name"
                onChange={(e) =>
                  setFormData((prev) => ({
                    ...prev,
                    name: e.target.value,
                  }))
                }
              />
            </InputCont>

            <InputCont>
              <InputField
                type="text"
                placeholder="Email(Optional)"
                onChange={(e) =>
                  setFormData((prev) => ({
                    ...prev,
                    email: e.target.value,
                  }))
                }
              />
            </InputCont>

            <InputCont>
              <InputField
                type="text"
                placeholder="Additional Details (optional)"
                onChange={(e) =>
                  setFormData((prev) => ({
                    ...prev,
                    additional_details: e.target.value,
                  }))
                }
              />
            </InputCont>

            <SubmitCont>
              <SubmitButton onClick={submitForm}>Submit</SubmitButton>
            </SubmitCont>
          </Form>
        ) : (
          "Thank You! your information has been submitted."
        )}
      </MainContainer>
    </>
  );
};

export default AbuseReportForm;
