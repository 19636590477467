import React from "react";
import {
  MainContainer,
  Heading,
  SubHeading,
  BubbleCont,
  BubbleLeft,
  BubbleRight,
  PolicyHeading,
  PolicyText,
} from "./PageTopSec.elements";

const PageTopSec = ({ heading, subHeading, policyHeading, policyText }) => {
  return (
    <>
      <MainContainer>
        <BubbleCont>
          <BubbleLeft src="assets/images/contact-us-bubble-1.svg" />
          <BubbleRight src="assets/images/contact-us-bubble-2.svg" />
        </BubbleCont>
        <Heading>{heading}</Heading>
        <SubHeading>{subHeading}</SubHeading>
        <PolicyHeading>{policyHeading}</PolicyHeading>
        <PolicyText>{policyText}</PolicyText>
      </MainContainer>
    </>
  );
};

export default PageTopSec;
