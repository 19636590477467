import styled from "styled-components";

export const MainContainer = styled.div`
  min-height: 1222px;
  max-width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 0 auto;

  @media screen and (max-width: 920px) {
    padding: 40px 0px;
  }
`;

export const HeadingContainer = styled.div``;

export const HeadingOne = styled.h1`
  font-family: "Poppins";
  font-style: normal;
  font-weight: bold;
  font-size: 44px;
  color: #262626;
  margin-top: 80px;

  @media screen and (max-width: 470px) {
    text-align: center;
  }
`;

export const HeadingTwo = styled.h1`
  font-family: "Poppins";
  font-style: normal;
  font-weight: bold;
  font-size: 44px;
  color: #ffab3e;
  margin-bottom: 68px;

  @media screen and (max-width: 470px) {
    text-align: center;
  }
`;

export const ContentContainer = styled.div`
  max-width: 1028px;
  width: 100%;
`;

export const FeaturesContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  @media screen and (max-width: 940px) {
    justify-content: space-evenly;
  }
`;

export const SingleFeature = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 104px;
`;

export const FeatureImage = styled.img``;

export const FeatureHeading = styled.h4`
  width: 210px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: bold;
  font-size: 38px;
  line-height: 51px;
  color: #262626;
  text-align: center;
`;

export const FeatureButton = styled.button`
  width: 160px;
  height: 55px;
  background: #262626;
  border-radius: 42px;
  border: none;

  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  text-align: center;
  color: #ffffff;
  cursor: pointer;
`;

// export const MainContainer = styled.div``;
