import React from "react";
import {
  MainContainer,
  InfoCont,
  Heading,
  SubHeading,
  BoxCont,
  BoxSecOne,
  BoxSecTwo,
  SingleBox,
  BoxHeading,
  BoxDesc,
  Bubble,
} from "./InfoSec.elements";

const InfoSec = () => {
  return (
    <>
      <MainContainer>
        <InfoCont>
          <Heading>Read more about our solutions</Heading>
          <SubHeading>
            Our client base represents a wide range of industries that have
            different needs and challenges. Learn more about our customized
            solutions.
          </SubHeading>

          <Bubble src="assets/images/info-sec-bubble.svg" />
        </InfoCont>

        <BoxCont>
          <BoxSecOne>
            <SingleBox>
              <BoxHeading>Sales & fundraising</BoxHeading>
              <BoxDesc>
                Utilize your resources the best way possible and take your sales
                or fundraising to the next level.
              </BoxDesc>
            </SingleBox>
          </BoxSecOne>

          <BoxSecTwo>
            <SingleBox>
              <BoxHeading>Appointment scheduling</BoxHeading>
              <BoxDesc>
                Strengthen the synergy between participants and organizers to
                improve your success.
              </BoxDesc>
            </SingleBox>

            <SingleBox>
              <BoxHeading>Service calls</BoxHeading>
              <BoxDesc>
                Reach more customers with less effort. Streamline your call
                flows and deliver greater user experience.
              </BoxDesc>
            </SingleBox>
          </BoxSecTwo>
        </BoxCont>
      </MainContainer>
    </>
  );
};

export default InfoSec;
