import React, { useEffect } from "react";
import {
  MainContainer,
  SingleDownload,
  InfoCont,
  IconCont,
  DownloadImage,
  InfoIcon,
  InfoName,
} from "./Download.elements";

const Download = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <MainContainer>
        <SingleDownload
          onClick={() => {
            window.location.href =
              "https://downloads.dialer360.com/downloads/eyebeam.zip";
          }}
        >
          <InfoCont>
            <InfoIcon src="assets/images/download-1.svg" />
            <InfoName></InfoName>
          </InfoCont>
          <IconCont background="#EE3A3B">
            <DownloadImage src="assets/images/download.svg" />
          </IconCont>
        </SingleDownload>

        <SingleDownload
          onClick={() => {
            window.location.href =
              "https://downloads.dialer360.com/downloads/3CX.msi";
          }}
        >
          <InfoCont>
            <InfoIcon src="assets/images/download-2.svg" />
            <InfoName></InfoName>
          </InfoCont>
          <IconCont background="#1E76C0">
            <DownloadImage src="assets/images/download.svg" />
          </IconCont>
        </SingleDownload>

        <SingleDownload
          onClick={() => {
            window.location.href =
              "https://downloads.dialer360.com/downloads/Zoiper_3.9_Setup.exe";
          }}
        >
          <InfoCont>
            <InfoIcon src="assets/images/download-3.svg" />
            <InfoName></InfoName>
          </InfoCont>
          <IconCont background="#F58220">
            <DownloadImage src="assets/images/download.svg" />
          </IconCont>
        </SingleDownload>

        <SingleDownload
          onClick={() => {
            window.location.href =
              "https://softradar.com/static/products/winmtr/distr/0/winmtr_softradar-com.zip";
          }}
        >
          <InfoCont>
            <InfoIcon src="assets/images/download-4.svg" />
            <InfoName></InfoName>
          </InfoCont>
          <IconCont background="#36A469">
            <DownloadImage src="assets/images/download.svg" />
          </IconCont>
        </SingleDownload>
      </MainContainer>
    </>
  );
};

export default Download;
