import React, { useState } from "react";
import styled from "styled-components";
const UploadLabel = styled.label`
  width: 117px;
  height: 32px;
  background: #262626;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  color: #ffffff;
  outline: none;
  border: none;
  cursor: pointer;
  text-align: center;
  line-height: 32px;
`;

const UploadCont = styled.div`
  width: 54%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media screen and (max-width: 620px) {
    width: 100%;
  }
`;

const UploadIcon = styled.img``;

const FileName = styled.h4`
  font-family: "Poppins";
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  color: #adadad;
`;

// const UploadCont = styled.div``;
// const UploadCont = styled.div``;

const FileUploader = (props) => {
  const [fileName, setFileName] = useState("No File Choosen");

  const hiddenFileInput = React.useRef(null);

  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };

  const handleChange = (event) => {
    const fileUploaded = event.target.files[0];
    setFileName(event.target.files[0].name);
    props.handleFile(fileUploaded);
  };

  return (
    <>
      <UploadCont>
        <UploadIcon src="assets/images/fileUpload.svg" />
        {/* <Button onClick={handleClick}>Choose File</Button> */}
        <UploadLabel>
          <input
            type="file"
            ref={hiddenFileInput}
            onChange={handleChange}
            style={{ display: "none" }}
          />
          Choose file
        </UploadLabel>
        <FileName>{fileName}</FileName>
      </UploadCont>
    </>
  );
};
export default FileUploader;
