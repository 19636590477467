import styled from "styled-components";
import { Link } from "react-router-dom";

export const MainContainer = styled.div`
  min-height: 843px;
  /* max-width: 1440px; */
  max-width: 100%;
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #f1fff7;
  position: relative;
  @media screen and (max-width: 860px) {
    padding: 40px 0px;
  }
`;

export const HeadingCont = styled.div`
  margin-bottom: 102px;
`;

export const Heading = styled.h2`
  font-family: "Poppins";
  font-style: normal;
  font-weight: bold;
  font-size: 44px;
  line-height: 56px;
  color: #262626;
  text-align: center;
  margin-bottom: 26px;
`;

export const SubHeading = styled.h4`
  max-width: 439px;
  width: 100%;
  font-family: "Poppins";
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 26px;
  color: #383838;
  text-align: center;
`;

export const ContentCont = styled.div`
  max-width: 1083px;
  width: 100%;
  display: flex;

  @media screen and (max-width: 920px) {
    flex-direction: column;
  }
`;

export const MenuCont = styled.div`
  max-width: 321px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  @media screen and (max-width: 920px) {
    max-width: 100%;
    padding: 0px 20px;
  }
`;

export const UL = styled.ul`
  @media screen and (max-width: 920px) {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    border-bottom: 2px solid #ebebeb;
  }
`;

export const ListItem = styled.li`
  list-style: none;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 25px;
  line-height: 40px;
  color: #262626;
  margin-bottom: 25px;
  cursor: pointer;

  @media screen and (max-width: 920px) {
    padding-right: 5px;
    padding-left: 5px;
    border-right: 2px solid #ebebeb;

    :last-child {
      border: none;
    }
  }

  :hover {
    color: #36a469;
  }
`;

export const DetailsCont = styled.div`
  min-height: 455px;
  height: 100%;
  border-left: 2px solid #ebebeb;

  padding: 17px 0px 20px 49px;
  position: relative;
`;

export const DetHeading = styled.h2`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 34px;
  line-height: 56px;
  color: #262626;
  margin-bottom: 23px;
`;

export const DetDesc = styled.p`
  margin-bottom: 34px;
`;

export const CheckPointsCont = styled.div`
  max-width: 540px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 55px;
`;

export const SingleCheckPoint = styled.div`
  max-width: ${(props) => (props.width ? props.width : "244px")};
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 6px;
`;

export const CheckIcon = styled.img`
  margin-right: 11px;
`;

export const CheckHeading = styled.div`
  font-family: "Poppins";
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 26px;
  color: #383838;
`;

export const ButtonCont = styled.div``;

export const GetStart = styled.a`
  display: block;
  width: 160px;
  height: 41px;
  text-decoration: none;
  line-height: 41px;
  background: #36a469;
  border-radius: 50px;
  outline: none;
  border: none;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  text-align: center;
  color: #ffffff;
  cursor: pointer;
  margin-right: 31px;
`;

export const Explore = styled(Link)`
  font-family: "Poppins";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #383838;
`;

export const BubbleRight = styled.img`
  position: absolute;
  right: 0;
  top: 65%;
`;
// export const Desc =styled.div``;
// export const Desc =styled.div``;
