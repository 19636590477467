import { Link } from "react-router-dom";
import styled from "styled-components";

export const MainContainer = styled.div`
  min-height: 452px;
  max-width: 1440px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 0 auto;

  @media screen and (max-width: 680px) {
    padding: 0px 26px;
    padding-top: 40px;
  }
`;

export const Heading = styled.h2`
  max-width: 676px;
  width: 100%;
  font-family: "Poppins";
  font-style: normal;
  font-weight: bold;
  font-size: 44px;
  line-height: 56px;
  color: #424242;
  text-align: center;
  margin-bottom: 18px;
`;

export const SubHeading = styled.h4`
  max-width: 457px;
  width: 100%;
  font-family: "Poppins";
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 26px;
  color: #787878;
  text-align: center;
  margin-bottom: 36px;
`;

export const Divider = styled.img`
  @media screen and (max-width: 980px) {
    width: 100%;
  }
`;

export const BubbleCont = styled.div`
  width: 100%;
  position: absolute;
  top: 2px;
  z-index: -99;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media screen and (max-width: 680px) {
    display: none;
  }
`;

export const BubbleRight = styled.img``;

export const BubbleLeft = styled.img``;
export const Home = styled(Link)``;
