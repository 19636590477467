import React, { useEffect } from "react";
import {
  Navbar,
  Hero,
  ServicesSecOne,
  NewSection,
  ServicesWithImage,
  LeadSec,
  Footer,
} from "../Components";

const SwitchPage = () => {
  const hero = {
    heading: "Carrier Platform for service providers",
    subHeading:
      "An all-in-one high performance carrier-grade Internet telephony switch solution for SIP network elements on a private cloud infrastructure.",
    image: "/assets/images/switch-header-image.svg",
  };


  const servicesSecTwo = {
    heading: "Softswitch Software",
    desc: [
      // <b>HOW DOES WORKFLOW WORK?</b>,
      <br />,
      "Our software provides your company with a carrier-grade platform and support to run your business. We have developed and maintain a dependable carrier-grade network driven by rock-solid performance and dependability with limitless possibilities. The softswitch is composed of a session border controller (SBC) and a billing platform that you can whitelabel and resell and is full of features that deliver class 4 and class 5 functionality.",
      <br />,
      <br />,
      // <b>WHY USE TRACKING?</b>,
      // "Not only can you orchestrate conversations and visually manage engagement paths for texts and calls but you will be able to dynamically route these conversations to the most appropriate destination.",
    ],
    // linkText: "Book a free Call",
    image: "assets/images/services-2.jpg",
    imageAlign: "row",
    width: "574px",
  };

  const servicesSecThree = {
    heading: "Hosted Switch Solution",
    desc: [
      // <b>How does Communications Software work?</b>,
      // <br />,

      "A hosted softswitch solution means never having to deal with the complexities and costs of deploying, managing, and maintaining a full switching and carrier management infrastructure. There are no hardware costs, no network costs, no engineering costs. Our solution is delivered as a ready-made, cost-effective, turnkey virtual infrastructure set to go full-throttle once your platform is active.",
      // <br />,
      // <br />,
      // <b>Why use Communications Software?</b>,
      // <br />,

      // "Set the cadence and tone of conversations through preferred channels for the best results.",
    ],
    // linkText: "Book a free Call",
    image: "assets/images/services-3.svg",
    imageAlign: "row-reverse",
  };

  const servicesSecFour = {
    heading: "XML RPC API",
    desc: [
      // <b>What is Inbox?</b>,
      // <br />,
      "Almost all core elements of our softswitch are controlled by the powerful XML-RPC APIs, enabling clients to integrate existing in-house management solutions, third-party technologies (such as SMS providers), and networking between different softswitch and SBC solutions.  ",
      <br />,
      // <br />,

      // <b>What is Workflow?</b>,
      <br />,
      "In short, the XML-RPC APIs not only offer an advanced method for system administration, they provide clients with an extremely powerful platform for developing extensive IP based telecommunications solutions.",
    ],
    // linkText: "Book a free Call",
    image: "assets/images/switch-4.svg",
    imageAlign: "row",
  };



  const itemsSecFive = [
    {
      icon: "assets/images/services-5-1.svg",
      heading: "Email notification",
      desc: "TalkAsia Switch has robust notification and alert policies that can be managed by the administrator and controlled by the users.",
    },
    {
      icon: "assets/images/services-5-2.svg",
      heading: "Customizable invoices",
      desc: "Create custom billing options for static or dynamically billed products and download invoice in pdf format.",
    },
    {
      icon: "assets/images/services-5-3.svg",
      heading: "Realtime LCR",
      desc: "Add vendors to a rate deck and they will immediately become available to the LCR engine for routing. Profit protection ensures you never route calls to a loss beyond your control. Routing based on LRN also possible.",
    },
  ];

  const NewFeatures = {
    heading: "Our Platform Key Features",

    featureOneHeading: "FLEXIBLE ROUTING TOOLS",
    featureOneDesc:
      "Quality routing provides the ability to to predefined alternative routes.",
    featureOneIcon: "/assets/images/switch-features-1.svg",

    featureTwoHeading: "REAL-TIME MONITORING",
    featureTwoDesc: [
      "our switch provides Real-Time monitoring with the ability to check data on a moment.",
    ],
    featureTwoIcon: "/assets/images/switch-features-2.svg",

    featureThreeHeading: "DID INVENTORY MANAGEMENT",
    featureThreeDesc: [
      "You can easily manage your DIDs directly from the administrative or reseller portals",
    ],
    featureThreeIcon: "/assets/images/switch-features-3.svg",

    featureFourHeading: "BILLING MANAGEMENT",
    featureFourDesc: [
      "Provide pricing solutions for pre-paid, post-paid, wholesale, carrier and call center solutions providers",
    ],
    featureFourIcon: "/assets/images/switch-features-4.svg",
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Navbar />
      <Hero
        heading={hero.heading}
        subHeading={hero.subHeading}
        image={hero.image}
        imageStyle={{ marginTop: "40px" }}
      />

      <ServicesWithImage
        heading={servicesSecTwo.heading}
        desc={servicesSecTwo.desc}
        linkText={servicesSecTwo.linkText}
        image={servicesSecTwo.image}
        imageAlign={servicesSecTwo.imageAlign}
        width={servicesSecTwo.width}
      />

      <NewSection
        position="center"
        heading={NewFeatures.heading}
        featureOneHeading={NewFeatures.featureOneHeading}
        featureTwoHeading={NewFeatures.featureTwoHeading}
        featureThreeHeading={NewFeatures.featureThreeHeading}
        featureFourHeading={NewFeatures.featureFourHeading}
        featureOneDesc={NewFeatures.featureOneDesc}
        featureTwoDesc={NewFeatures.featureTwoDesc}
        featureThreeDesc={NewFeatures.featureThreeDesc}
        featureFourDesc={NewFeatures.featureFourDesc}
        featureOneIcon={NewFeatures.featureOneIcon}
        featureTwoIcon={NewFeatures.featureTwoIcon}
        featureThreeIcon={NewFeatures.featureThreeIcon}
        featureFourIcon={NewFeatures.featureFourIcon}
      />

      <ServicesWithImage
        heading={servicesSecThree.heading}
        desc={servicesSecThree.desc}
        linkText={servicesSecThree.linkText}
        image={servicesSecThree.image}
        imageAlign={servicesSecThree.imageAlign}
      />

      <ServicesSecOne
        items={itemsSecFive}
        title="Fulfill your communication needs"
        style={{ background: "#F1FFF7" }}
        height="761px"
      />

      <ServicesWithImage
        heading={servicesSecFour.heading}
        desc={servicesSecFour.desc}
        linkText={servicesSecFour.linkText}
        image={servicesSecFour.image}
        imageAlign={servicesSecFour.imageAlign}
      />

      <LeadSec />
      <Footer />
    </>
  );
};

export default SwitchPage;
