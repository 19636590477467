import React from "react";
import {
  MainContainer,
  TitleCont,
  Title,
  ServicesCont,
  SingleService,
  ServiceIcon,
  ServiceHeading,
  ServiceDesc,
  ServiceBubble,
} from "./ServicesSecOne.elements";

const ServicesSecOne = ({ title, height = "", items, bubble, style }) => {
  return (
    <>
      <MainContainer height={height} style={style}>
        {bubble ? (
          <ServiceBubble src="assets/images/service-1-bubble.svg" />
        ) : (
          ""
        )}
        {title ? (
          <TitleCont>
            <Title>{title}</Title>
          </TitleCont>
        ) : (
          ""
        )}

        <ServicesCont>
          {items.map((item) => {
            return (
              <SingleService>
                <ServiceIcon src={item.icon} />
                <ServiceHeading>{item.heading}</ServiceHeading>
                <ServiceDesc>{item.desc}</ServiceDesc>
              </SingleService>
            );
          })}
        </ServicesCont>
      </MainContainer>
    </>
  );
};

export default ServicesSecOne;
