import React from "react";
import { useMediaQuery } from "react-responsive";
import {
  MainContainer,
  BubbleLeft,
  BubbleRight,
  TextContainer,
  ImageContainer,
  Heading,
  Desc,
  Button,
  Image,
  BubbleCon,
} from "./Hero.elements";

const Hero = ({ heading, headingSize, subHeading, image, imageStyle }) => {

  return (
    <>
      <MainContainer>
        <BubbleCon>
          <BubbleLeft src="/assets/images/bubble-left.svg" />
          <BubbleRight src="/assets/images/bubble-right.svg" />
        </BubbleCon>

        <TextContainer>
          <Heading headingSize={headingSize}>{heading}</Heading>
          <Desc>{subHeading}</Desc>
          <Button href="https://register.talkasiavoip.com/" target="_blank">
            Get Start Now
          </Button>
        </TextContainer>
        <ImageContainer>
          <Image src={image} style={imageStyle} />
        </ImageContainer>
      </MainContainer>
    </>
  );
};

export default Hero;
