import styled from "styled-components";

export const MainContainer = styled.div`
  min-height: 670px;
  max-width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 0 auto;
  background: ${(props) => (props.bg ? props.bg : "")};

  @media screen and (max-width: 920px) {
    padding: 40px 0px;
  }
`;

export const HeadingContainer = styled.div`
  max-width: 1135px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: ${(props) =>
    props.position === "left" ? "flex-start" : "center"};
`;

export const SubHeading = styled.h5`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  color: #36a469;
  margin: 0;
  margin-bottom: 25px;

  @media screen and (max-width: 920px) {
    margin: 0 auto;
    text-align: center;
  }
`;

export const Heading = styled.h1`
  max-width: 849px;
  width: 100%;
  font-family: "Poppins";
  font-style: normal;
  font-weight: bold;
  font-size: 44px;
  line-height: 56px;
  color: #262626;
  margin-bottom: 74px;
  text-align: ${(props) => (props.position === "left" ? "left" : "center")};

  @media screen and (max-width: 920px) {
    max-width: 90%;
    margin: 0 auto;
    margin-bottom: 74px;
    font-size: 40px;
    text-align: center;
  }
`;

export const FeaturesContainer = styled.div`
  max-width: 1018px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;

  @media screen and (max-width: 920px) {
    justify-content: center;
  }
`;

export const SingleFeature = styled.div`
  width: 233px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 920px) {
    margin-bottom: 40px;
  }
`;

export const Icon = styled.img`
  margin-bottom: 43px;
`;

export const FeatureHeading = styled.h6`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 23px;
  text-align: center;
  margin: 0;
  margin-bottom: 18px;
`;

export const FeatureDesc = styled.p`
  font-family: "Poppins";
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 26px;
  text-align: center;
  color: #959595;
  margin: 0;
`;

// export const MainContainer = styled.div``;
