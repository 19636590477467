import styled from "styled-components";

export const MainContainer = styled.div`
  min-height: ${(props) => (props.height !== "" ? props.height : "587px")};
  max-width: 1440px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: -3;

  @media screen and (max-width: 980px) {
    padding: 40px 0px;
  }
`;

export const TitleCont = styled.div``;

export const Title = styled.h2`
  font-family: "Poppins";
  font-style: normal;
  font-weight: bold;
  font-size: 44px;
  color: #262626;
  margin-bottom: 70px;

  @media screen and (max-width: 980px) {
    text-align: center;
    padding: 0px 5px;
  }
`;

export const ServicesCont = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-wrap: wrap;
`;

export const SingleService = styled.div`
  height: 388px;
  max-width: 356px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 49px 32px;
  background: #ffffff;
  box-shadow: 0px 0px 24px 10px rgba(204, 204, 204, 0.1);

  @media screen and (max-width: 1070px) {
    margin-bottom: 20px;
  }
`;

export const ServiceIcon = styled.img`
  margin-bottom: 43.6px;
`;

export const ServiceHeading = styled.h2`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 25px;
  text-align: center;
  color: #000000;
  margin-bottom: 38px;
`;

export const ServiceDesc = styled.p`
  font-family: "Poppins";
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  text-align: center;
  color: #959595;
`;

export const ServiceBubble = styled.img`
  position: absolute;
  top: -76px;
  left: 150px;
  z-index: -2;
`;
// export const Desc =styled.div``;
// export const Desc =styled.div``;
// export const Desc =styled.div``;
// export const Desc =styled.div``;
