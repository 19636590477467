import React, { useState } from "react";
import {
  MainContainer,
  SubContainer,
  CookieImage,
  CookieContent,
  Heading,
  Desc,
  AcceptButton,
} from "./Cookie.element";

const Cookie = () => {
  const [showCookie, setShowCookie] = useState(true);

  if (showCookie === true) {
    return (
      <MainContainer>
        <SubContainer>
          <CookieImage src="assets/images/cookie.svg" />
          <CookieContent>
            <Heading>Our Cookie Policy</Heading>
            <Desc>
              TalkAsiaVoip uses cookies to provide necessary website
              functionality, improve your experience and analyze our traffic. By
              using our website, you agree to our Privacy Policy and our Cookies
              Policy.
            </Desc>
          </CookieContent>
          <AcceptButton onClick={() => setShowCookie(false)}>
            Accept
          </AcceptButton>
        </SubContainer>
      </MainContainer>
    );
  } else {
    return "";
  }
};

export default Cookie;
