import React from "react";
import {
  MainContainer,
  ContentCont,
  Heading,
  Desc,
  MapCont,
  Map,
} from "./AboutSecFour.elements";

const AboutSecFour = () => {
  return (
    <>
      <MainContainer>
        <ContentCont>
          <Heading>Why We’re Different</Heading>
          <Desc>
            <Desc>
              At TalkAsia we always go the extra mile with, and for, our
              clients.
            </Desc>
            <br />
            we are responsive and engaged in every interaction and that means
            we’re always listening to our clients’ needs. This enables us to
            deliver solutions that are tailored just for their business and
            ensures we’re able to serve as an expert extension of their IT
            teams—whenever and wherever they need us.
            <br />
            <br />
            Today, we are very proud to provide our service to awesome customers
            in 45 different countries spread across six continents.
          </Desc>
        </ContentCont>

        <MapCont>
          <Map src="assets/images/about-map.svg" />
        </MapCont>
      </MainContainer>
    </>
  );
};

export default AboutSecFour;
