import styled from "styled-components";

export const MainContainer = styled.div`
  min-height: 790px;
  max-width: 1440px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 0 auto;
  background: #f1fff7;

  @media screen and (max-width: 1260px) {
    padding: 40px 0px;
  }
`;

export const HeadingCont = styled.div`
  margin-bottom: 72px;
`;

export const Heading = styled.h2`
  font-family: "DM Sans";
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  text-align: center;
  letter-spacing: 2.1px;
  text-transform: uppercase;
  color: #36a469;
  margin-bottom: 22px;
`;

export const SubHeading = styled.h4`
  font-family: "DM Sans";
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  text-align: center;
  letter-spacing: -1.5px;
  color: #0f2137;
`;

export const TestiCont = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-wrap: wrap;
`;

export const SingleTesti = styled.div`
  max-width: 420px;
  width: 100%;
  min-height: 400px;
  background: #ffffff;
  box-shadow: 0px 6px 47px rgba(38, 78, 118, 0.1);
  padding: 35px 39px;

  @media screen and (max-width: 1260px) {
    margin-bottom: 40px;
  }
`;

export const Rating = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 40px;
`;

export const Image = styled.img``;

export const Review = styled.p`
  font-family: "DM Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 38px;
  color: #343d48;
  margin-bottom: 46px;
`;

export const User = styled.div`
  display: flex;
  align-items: center;
`;

export const UserImage = styled.img`
  margin-right: 19px;
`;

export const UserInfo = styled.div``;

export const Name = styled.p`
  font-family: "DM Sans";
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  letter-spacing: -0.4px;
  color: #0f2137;
  margin-bottom: 8px;
`;

export const Date = styled.p`
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  letter-spacing: -0.375px;
  color: #36a469;
`;
