import React from "react";
import {
  MainContainer,
  Heading,
  FeaturesContainer,
  SingleFeature,
  FeatureImage,
  FeatureHeading,
  FeatureDesc,
} from "./DialerFeatureSecThreeCol.elements";

const DialerFeatureSecThreeCol = () => {
  return (
    <MainContainer>
      <Heading>Simple for admins and users</Heading>
      <FeaturesContainer>
        <SingleFeature>
          <FeatureImage src="assets/images/feature-col-three-1.svg" />
          <FeatureHeading>One-click integrations</FeatureHeading>
          <FeatureDesc>
            Connect Aircall with your CRM or Helpdesk to centralize activity and
            automate call logging.
          </FeatureDesc>
        </SingleFeature>

        <SingleFeature>
          <FeatureImage src="assets/images/feature-col-three-2.svg" />
          <FeatureHeading>Intuitive controls</FeatureHeading>
          <FeatureDesc>
            Reduce training from months to minutes with elegant apps your agents
            will appreciate.
          </FeatureDesc>
        </SingleFeature>

        <SingleFeature>
          <FeatureImage src="assets/images/feature-col-three-3.svg" />
          <FeatureHeading>Easy to manage</FeatureHeading>
          <FeatureDesc>
            Add new numbers, teammates, or routing rules on the fly. No IT
            consultants required.
          </FeatureDesc>
        </SingleFeature>
      </FeaturesContainer>
    </MainContainer>
  );
};

export default DialerFeatureSecThreeCol;
