import { Link } from "react-router-dom";
import styled from "styled-components";

export const MainContainer = styled.div`
  min-height: 670px;
  max-width: 1209px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: ${(props) => (props.imageAlign ? props.imageAlign : "row")};
  align-items: center;
  justify-content: space-between;
  position: relative;
  z-index: -3;

  @media screen and (max-width: 980px) {
    flex-direction: column;
    padding: 40px 0px;
  }
`;

export const InfoCont = styled.div`
  max-width: 490px;
  width: 100%;
`;

export const Heading = styled.h2`
  width: ${(props) => (props.width ? props.width : "")};
  font-family: "Poppins";
  font-style: normal;
  font-weight: bold;
  font-size: 44px;
  color: #262626;
  margin-bottom: 19px;

  @media screen and (max-width: 980px) {
    text-align: center;
  }
`;

export const Desc = styled.p`
  font-family: "Poppins";
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 26px;
  color: #383838;
  margin-bottom: 19px;

  @media screen and (max-width: 980px) {
    text-align: center;
  }
`;

export const InfoLink = styled(Link)`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 26px;
  color: #36a469;
  cursor: pointer;

  @media screen and (max-width: 980px) {
    display: block;
    width: 100%;
    text-align: center;
  }
`;

export const ImageCont = styled.div``;

export const Image = styled.img`
  @media screen and (max-width: 980px) {
    width: 100%;
  }
`;

// export const Desc =styled.div``;
// export const Desc =styled.div``;
