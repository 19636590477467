import React from "react";
import {
  MainContainer,
  LogoContainer,
  Logo,
  Bubble,
} from "./ReviewIcon.elements";

const ReviewIcon = () => {
  return (
    <>
      <MainContainer>
        <Bubble src="assets/images/chat-bubble.svg" />
        <LogoContainer>
          <Logo src="assets/images/logo-1.svg" />
          <Logo src="assets/images/logo-2.svg" />
          <Logo src="assets/images/logo-3.svg" />
          <Logo src="assets/images/logo-4.svg" />
          <Logo src="assets/images/logo-5.svg" />
        </LogoContainer>
      </MainContainer>
    </>
  );
};

export default ReviewIcon;
