import React from "react";
import { Navbar, PageTopSec, RequestForm, Footer } from "../Components";

const InformationRequestPage = () => {
  return (
    <>
      <Navbar color="#535353" />
      <PageTopSec
        heading="TalkAsia Subpoena / Law Enforcement Request"
        subHeading="This form must be used to submit any subpoena, court order, or law enforcement request for information.
        TalkAsia charges $100.00 for research & compliance with civil litigation subpoenas."
        policyHeading="Policy"
        policyText=" TalkAsia is a service provider and does not send SMS messages or
        phone calls directly to end-users. Please fill out this Contact
        form to report a phone number. All phone numbers will be
        investigated by our carrier compliance team."
      />
      <RequestForm />
      <Footer />
    </>
  );
};

export default InformationRequestPage;
