import React from "react";
import {
  MainContainer,
  SubContainer,
  HighlightSec,
  SingleHighlight,
  HighlightHeading,
  HighlightText,
  HighlightBtn,
  FeatureSec,
  FeatureCol,
  SingleFeature,
  FeatureImage,
  FeatureHeading,
  FeatureDesc,
} from "./VoipFeatureSec.element";

const VoipFeatureSec = () => {
  return (
    <MainContainer>
      <SubContainer>
        <HighlightSec>
          <SingleHighlight>
            <HighlightHeading>HIGHLIGHTED FEATURES</HighlightHeading>
            <HighlightText>
              We are your all-inclusive voice solutions provider.
            </HighlightText>
            <HighlightBtn>Get Free Audit</HighlightBtn>
          </SingleHighlight>
        </HighlightSec>

        <FeatureSec>
          <FeatureCol>
            <SingleFeature>
              <FeatureImage src="assets/images/voip-feature-sec-1.svg" />
              <FeatureHeading>Flexible Pricing</FeatureHeading>
              <FeatureDesc>
                Flat rate, rate by jurisdiction or rate deck by NPA-NXX
              </FeatureDesc>
            </SingleFeature>

            <SingleFeature>
              <FeatureImage src="assets/images/voip-feature-sec-2.svg" />
              <FeatureHeading>Single Provider Solution</FeatureHeading>
              <FeatureDesc>
                We can handle all of your calling needs, fraud monitoring,
                mitigation and more
              </FeatureDesc>
            </SingleFeature>
          </FeatureCol>

          <FeatureCol>
            <SingleFeature>
              <FeatureImage src="assets/images/voip-feature-sec-3.svg" />
              <FeatureHeading>Termination Footprint</FeatureHeading>
              <FeatureDesc>
                One quality connection for comprehensive termination of local,
                long distance and international voice service
              </FeatureDesc>
            </SingleFeature>

            <SingleFeature>
              <FeatureImage src="assets/images/voip-feature-sec-4.svg" />
              <FeatureHeading>Intuitive Web Portal</FeatureHeading>
              <FeatureDesc>
                Easily configure SIP trunks with E911 and deploy remotely
              </FeatureDesc>
            </SingleFeature>
          </FeatureCol>

          <FeatureCol>
            <SingleFeature>
              <FeatureImage src="assets/images/voip-feature-sec-5.svg" />
              <FeatureHeading>Toll-Free Numbers</FeatureHeading>
              <FeatureDesc>
                Extensive number inventory for vanity and sequential number
                blocks
              </FeatureDesc>
            </SingleFeature>

            <SingleFeature>
              <FeatureImage src="assets/images/voip-feature-sec-6.svg" />
              <FeatureHeading>Stop Spoofed Robocalls</FeatureHeading>
              <FeatureDesc>
                STIR/SHAKEN support to stop spoofed robocalls and verify
                authenticity of your own calls
              </FeatureDesc>
            </SingleFeature>
          </FeatureCol>
        </FeatureSec>
      </SubContainer>
    </MainContainer>
  );
};

export default VoipFeatureSec;
