import React from "react";
import {
  MainContainer,
  HeadingContainer,
  HeadingOne,
  HeadingTwo,
  ContentContainer,
  FeaturesContainer,
  SingleFeature,
  FeatureImage,
  FeatureHeading,
  FeatureButton,
} from "./AmazonFeatures.elements";

const AmazonFeatures = () => {
  return (
    <MainContainer>
      <HeadingContainer>
        <HeadingOne>We manage all your</HeadingOne>
        <HeadingTwo>Amazon advertising</HeadingTwo>
      </HeadingContainer>

      <ContentContainer>
        <FeaturesContainer>
          <SingleFeature>
            <FeatureImage src="assets/images/amz-feature-1.png" />
            <FeatureHeading>Sponsored Products</FeatureHeading>
          </SingleFeature>

          <SingleFeature>
            <FeatureImage src="assets/images/amz-feature-2.png" />
            <FeatureHeading>Sponsored Brands</FeatureHeading>
          </SingleFeature>

          <SingleFeature>
            <FeatureImage src="assets/images/amz-feature-3.png" />
            <FeatureHeading>Sponsored Display</FeatureHeading>
          </SingleFeature>

          <SingleFeature>
            <FeatureImage src="assets/images/amz-feature-4.png" />
            <FeatureHeading>Sponsored Brands Video</FeatureHeading>
          </SingleFeature>

          <SingleFeature>
            <FeatureImage src="assets/images/amz-feature-5.png" />
            <FeatureHeading>Amazon DSP</FeatureHeading>
          </SingleFeature>

          <SingleFeature>
            <FeatureImage src="assets/images/amz-feature-6.png" />
            <FeatureButton>Get Free Audit</FeatureButton>
          </SingleFeature>
        </FeaturesContainer>
      </ContentContainer>
    </MainContainer>
  );
};

export default AmazonFeatures;
