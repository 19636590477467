import React from "react";
import { MainContainer, TextCont, Heading, Text } from "./AboutSecTwo.elements";

const AboutSecTwo = () => {
  return (
    <>
      <MainContainer>
        <TextCont>
          <Heading>What do we stand for?</Heading>
          <Text>
            Our vision is to be the best-of-breed cloud-native outbound solution
            allowing customers to get in touch with the right people at the
            right time.
            <br />
            <br />
            Our mission is to ensure enterprise-grade features to a broad
            audience through a highly intuitive user interface. New features and
            changes to the existing UI are centered around customer feedback. At
            the same time, we want to ensure quality, data assurance, and the
            highest up-time.
            <br />
            <br />
            We believe that good communication leads to great experiences. In
            many ways, communication is our basis for existence. We believe that
            Together Everyone Achieves More. We use this TEAM spirit to ensure
            that our customers have the best possible experience with our
            service. Our entire team knows how each team player contributes to
            our mission on an everyday basis.
          </Text>
        </TextCont>
      </MainContainer>
    </>
  );
};

export default AboutSecTwo;
