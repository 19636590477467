import styled from "styled-components";

export const MainContainer = styled.div`
  min-height: 629px;
  max-width: 1440px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 0 auto;
  background: #36a469;

  @media screen and (max-width: 640px) {
    padding: 40px 0px;
  }
`;

export const HeadingCont = styled.div``;

export const Heading = styled.h2`
  font-family: "Poppins";
  font-style: normal;
  font-weight: bold;
  font-size: 44px;
  align-items: center;
  text-align: center;
  color: #ffffff;
  margin-bottom: 20px;
`;

export const SubHeading = styled.h4`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  text-align: center;
  color: #f1f1f1;
  margin-bottom: 128px;
`;

export const FeaturesCont = styled.div`
  min-height: 224px;
  max-width: 1086px;
  width: 100%;
  display: flex;
  align-items: center;

  @media screen and (max-width: 640px) {
    flex-direction: column;
  }
`;

export const SingleFeature = styled.div`
  max-width: 362px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-right: 2px solid #ebebeb;

  @media screen and (max-width: 640px) {
    margin-bottom: 40px;
    border-right: none;
    padding-bottom: 40px;
    border-bottom: 2px solid #ebebeb;
  }

  :last-child {
    border: none;
  }
`;

export const FeatureIcon = styled.img`
  margin-bottom: 25px;
`;

export const FeatureNumbers = styled.h4`
  font-family: "Poppins";
  font-style: normal;
  font-weight: bold;
  font-size: 44px;
  text-align: center;
  color: #ffffff;
  margin-bottom: 9px;
`;

export const FeatureTitle = styled.h2`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  text-align: center;
  color: #ffffff;
  margin-bottom: 2px;
`;

export const FeatureSubTitle = styled.h4`
  font-family: "Poppins";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  text-align: center;
  color: #ffffff;
`;

// export const Desc =styled.div``;
// export const Desc =styled.div``;
// export const Desc =styled.div``;
