import styled from "styled-components";

export const MainContainer = styled.div`
  min-height: 1266px;
  max-width: 1440px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 0 auto;

  @media screen and (max-width: 800px) {
    padding: 40px 26px;
  }
`;

export const ContentCont = styled.div`
  min-height: 603px;
  max-width: 802px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Heading = styled.h2`
  font-family: "Poppins";
  font-style: normal;
  font-weight: bold;
  font-size: 44px;
  align-items: center;
  color: #262626;
  margin-bottom: 43px;
`;

export const Desc = styled.h4`
  font-family: "Poppins";
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 26px;
  color: #787878;
`;

export const MapCont = styled.div`
  min-height: 663px;
  max-width: 802px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Map = styled.img`
  @media screen and (max-width: 800px) {
    min-height: 300px;
    width: 100%;
  }
`;

// export const Desc =styled.div``;
// export const Desc =styled.div``;
// export const Desc =styled.div``;
// export const Desc =styled.div``;
// export const Desc =styled.div``;
// export const Desc =styled.div``;
// export const Desc =styled.div``;
