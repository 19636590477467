import React from "react";
import {
  MainContainer,
  HeadingCont,
  Heading,
  SubHeading,
  FeatureCont,
  SingleFeature,
  Icon,
  Title,
  Desc,
  ButtonCont,
  GetStart,
} from "./FeatureSecThree.elements";

const FeatureSecThree = () => {
  return (
    <>
      <MainContainer>
        <HeadingCont>
          <Heading>How we help your business grow</Heading>
          <SubHeading>
            Having developed smart solutions for numerous types of
            organizations, we know what matters most.
          </SubHeading>
        </HeadingCont>

        <FeatureCont>
          <SingleFeature>
            <Icon src="assets/images/feature-3-1.svg" />
            <Title>High availability all around the world</Title>
            <Desc>
              Our services experience uptime more than 99.99% of the time.
            </Desc>
          </SingleFeature>

          <SingleFeature>
            <Icon src="assets/images/feature-3-2.svg" />
            <Title>Professional and free support</Title>
            <Desc>
              Our support team can guide you through phone calls, chat, and
              email.
            </Desc>
          </SingleFeature>

          <SingleFeature>
            <Icon src="assets/images/feature-3-3.svg" />
            <Title>Get ready to experience growth</Title>
            <Desc>
              Add some tweaks that will lead to better results.
              <br />
              &nbsp;
            </Desc>
          </SingleFeature>

          <SingleFeature>
            <Icon src="assets/images/feature-3-4.svg" />
            <Title>Customer-driven innovation</Title>
            <Desc>
              Our customers have a strong influence on our roadmap.
              <br />
              &nbsp;
            </Desc>
          </SingleFeature>
        </FeatureCont>

        <ButtonCont>
          <GetStart href="https://register.talkasiavoip.com/" target="_blank">
            Get Start Now
          </GetStart>
        </ButtonCont>
      </MainContainer>
    </>
  );
};

export default FeatureSecThree;
