import React from "react";
import { Navbar, AboutHero, PriceTables, LeadSec, Footer } from "../Components";

const PriceTablesPage = () => {
  return (
    <>
      <Navbar color="#535353" />
      <AboutHero
        heading="Pricing Plans"
        subHeading="Select your pricing plan fit for your business needs!"
      />
      <PriceTables />
      <LeadSec />
      <Footer />
    </>
  );
};

export default PriceTablesPage;
