import styled from "styled-components";

export const MainContainer = styled.div`
  /* max-width: 1440px; */
  max-width: 100%;
  min-height: 779px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  margin: 0 auto;
  background: #fafafa;

  @media screen and (max-width: 690px) {
    justify-content: center;
  }

  @media screen and (max-width: 1040px) {
    padding: 40px 0px;
  }
`;

export const Heading = styled.h1`
  font-family: "Poppins";
  font-style: normal;
  font-weight: bold;
  font-size: 44px;
  text-align: center;
  color: #262626;
  margin-bottom: 84px;
`;

export const FeaturesContainer = styled.div`
  max-width: 1440px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-evenly;
`;

export const SingleFeature = styled.div`
  width: 339px;
  height: 327px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  box-shadow: 0px 0px 24px 10px rgba(204, 204, 204, 0.1);

  @media screen and (max-width: 1040px) {
    margin-bottom: 20px;
  }
`;

export const FeatureImage = styled.img`
  width: 65px;
  margin-bottom: 31px;
`;

export const FeatureHeading = styled.h4`
  font-family: "Poppins";
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  text-align: center;
  color: #000000;
  margin-bottom: 15px;
`;

export const FeatureDesc = styled.p`
  max-width: 289px;
  width: 100%;
  font-family: "Poppins";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  text-align: center;
  line-height: 26px;
  color: #959595;
`;

// export const div = styled.div``;
// export const div = styled.div``;
// export const div = styled.div``;
