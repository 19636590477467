import React from "react";
import {
  MainContainer,
  Heading,
  SubHeading,
  Divider,
  BubbleCont,
  BubbleRight,
  BubbleLeft,
} from "./AboutHero.elements";

const AboutHero = ({ heading, subHeading }) => {
  return (
    <>
      <MainContainer>
        <BubbleCont>
          <BubbleRight src="assets/images/about-bubble-right.svg" />
          <BubbleLeft src="assets/images/about-bubble-left.svg" />
        </BubbleCont>

        <Heading>{heading}</Heading>
        <SubHeading>{subHeading}</SubHeading>

        <Divider src="assets/images/about-divider.svg" />
      </MainContainer>
    </>
  );
};

export default AboutHero;
