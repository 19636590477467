import styled from "styled-components";

export const MainContainer = styled.div`
  min-height: 660px;
  max-width: 1151px;
  width: 100%;
  margin: 0 auto;
  display: flex;

  @media screen and (max-width: 980px) {
    flex-direction: column;
    background: #36a469;
    padding-top: 40px;
  }
`;

export const BubbleCon = styled.div`
  position: absolute;

  max-width: 1800px;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;

  @media screen and (max-width: 980px) {
    display: none;
  }
`;

export const BubbleLeft = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  z-index: -99;
`;
export const BubbleRight = styled.img`
  position: absolute;
  top: 0;
  right: 0;
  z-index: -99;
`;
export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 980px) {
    align-items: center;
  }
`;
export const ImageContainer = styled.div`
  display: flex;
  align-items: center;
  @media screen and (max-width: 980px) {
    justify-content: center;
  }
`;
export const Heading = styled.h2`
  max-width: 500px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: bold;
  font-size: ${(props) => (props.headingSize ? props.headingSize : "44px")};
  display: flex;
  align-items: center;
  color: #ffffff;
  line-height: 48px;
  margin-top: 50px;
  margin-bottom: 10px;

  @media screen and (max-width: 980px) {
    max-width: 100%;
    font-size: 34px;
    text-align: center;
    margin-top: 0px;
  }
`;
export const Desc = styled.div`
  max-width: 329px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  display: flex;
  align-items: center;
  color: #eeeeee;
  margin-bottom: 23px;

  @media screen and (max-width: 980px) {
    max-width: 100%;
    font-size: 12px;
    text-align: center;
  }
`;
export const Button = styled.a`
  display: block;
  width: 160px;
  height: 55px;
  text-decoration: none;
  line-height: 55px;
  background: #262626;
  border-radius: 42px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  text-align: center;
  color: #ffffff;
  border: none;
  outline: none;
  cursor: pointer;
`;
export const Image = styled.img`
  position: relative;
  bottom: 60px;
  width: 100%;

  @media screen and (max-width: 980px) {
    width: 80%;
    bottom: 0px;
    margin-top: 40px;
  }
`;
export const BubbleMob = styled.img`
  position: absolute;
  top: 0;
  width: 100%;
  z-index: -99;
`;

// export const Desc =styled.div``;
