import React, { useState } from "react";
import {
  MainContainer,
  Form,
  FieldCont,
  InputCont,
  VerifyCont,
  VerifyContBottom,
  InputField,
  Icon,
  InputTextArea,
  SubmitButton,
  SubmitCont,
  Error,
  SendCode,
  IconSendBtn,
  VerifyCode,
  InputFieldVerification,
} from "./ContactForm.elements";

import axios from "axios";

const ContactForm = () => {
  const [validationError, setValidationError] = useState("");
  const [showForm, setShowForm] = useState(true);
  const [otp, setOtp] = useState();
  const [verifyOtp, setVerifyOtp] = useState();
  const [verified, setVerified] = useState(false);
  const [showVerification, setShowVerification] = useState(false);
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    form: "contact",
    name: "",
    email: "",
    phone: "",
    skype: "",
    message: "",
  });

  const validateEmail = async (email) => {
    let emailAddress = email.target.value;
    const response = await axios.get(
      `https://open.kickbox.com/v1/disposable/${emailAddress}`
    );
    response.data.disposable === true
      ? setValidationError("Please enter a valid email address")
      : setValidationError("");
  };

  const sendOtp = (e) => {
    e.preventDefault();
    setLoading(true);

    const otp = Math.floor(100000 + Math.random() * 900000);
    setOtp(otp);
    if (formData.email !== "") {
      axios
        .post("https://register.talkasiavoip.com/public/api/sendemailotpvoip", {
          email: formData.email,
          otp: otp,
        })
        .then((response) => {
          if (response.data.status === "success") {
            setError("");
            setShowVerification(true);
            setLoading(false);
          }
        });
    }
  };

  const handleVerifyOtp = (e) => {
    e.preventDefault();
    if (verifyOtp === otp) {
      setVerified(true);
      setShowVerification(false);
    } else {
      setError("Invalid OTP");
    }
  };

  const submitForm = async (e) => {
    e.preventDefault();
    const response = await axios.post(
      "https://register.talkasiavoip.com/public/api/forms",
      {
        data: formData,
      }
    );
    setShowForm(false);
  };

  const onChangeHandler = (e) => {
    validateEmail(e);
    setFormData((prev) => ({ ...prev, email: e.target.value }));
  };

  return (
    <>
      <MainContainer>
        {showForm ? (
          <Form>
            <FieldCont>
              <InputCont>
                <InputField
                  type="text"
                  placeholder="Name"
                  onChange={(e) =>
                    setFormData((prev) => ({ ...prev, name: e.target.value }))
                  }
                />
                <Icon src="assets/images/contact-input-icon-1.svg" />
              </InputCont>
            </FieldCont>

            <FieldCont>
              <InputCont>
                <InputField
                  type="email"
                  placeholder="Email"
                  onChange={(e) => onChangeHandler(e)}
                  required
                  disabled={verified ? true : false}
                />
              </InputCont>
              <Error>{validationError}</Error>
            </FieldCont>

            <FieldCont>
              {showVerification ? (
                <>
                  <VerifyContBottom>
                    <InputFieldVerification
                      type="text"
                      placeholder="Please enter the OTP"
                      onChange={(e) => setVerifyOtp(e.target.value)}
                    />
                    <VerifyCode onClick={handleVerifyOtp}>Submit</VerifyCode>
                  </VerifyContBottom>
                  <Error>{error}</Error>
                </>
              ) : !verified ? (
                <SendCode onClick={sendOtp} disabled={verified ? true : false}>
                  {loading ? (
                    <IconSendBtn src="assets/images/loader.svg" />
                  ) : (
                    ""
                  )}
                  Verify Now
                </SendCode>
              ) : (
                <SendCode disabled="true" style={{ background: "#36a469" }}>
                  Verified
                </SendCode>
              )}
            </FieldCont>

            <FieldCont>
              <InputCont>
                <InputField
                  type="text"
                  placeholder="Phone"
                  onChange={(e) =>
                    setFormData((prev) => ({ ...prev, phone: e.target.value }))
                  }
                />
                <Icon src="assets/images/contact-input-icon-3.svg" />
              </InputCont>
            </FieldCont>

            <FieldCont>
              <InputCont>
                <InputField
                  type="text"
                  placeholder="Skype ID"
                  onChange={(e) =>
                    setFormData((prev) => ({ ...prev, skype: e.target.value }))
                  }
                />
                <Icon src="assets/images/contact-input-icon-3.svg" />
              </InputCont>
            </FieldCont>

            <FieldCont>
              <InputCont>
                <InputTextArea
                  placeholder="Message"
                  onChange={(e) =>
                    setFormData((prev) => ({
                      ...prev,
                      message: e.target.value,
                    }))
                  }
                  style={{ resize: "none" }}
                />
                <Icon src="assets/images/contact-input-icon-4.svg" />
              </InputCont>
            </FieldCont>

            <SubmitCont>
              <SubmitButton
                style={{ background: verified ? "" : "#d3d3d3" }}
                onClick={submitForm}
                disabled={verified ? false : true}
              >
                Submit
              </SubmitButton>
            </SubmitCont>
          </Form>
        ) : (
          "Thank You! your information has been submitted."
        )}
      </MainContainer>
    </>
  );
};

export default ContactForm;
