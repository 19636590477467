import React from "react";
import { Navbar, PageTopSec, ServiceAgreement, Footer } from "../Components";

const ServiceAgreementPage = () => {
  return (
    <>
      <Navbar color="#535353" />
      <PageTopSec heading="Master Services Agreement" />
      <ServiceAgreement />
      <Footer />
    </>
  );
};

export default ServiceAgreementPage;
