import React, { useEffect } from "react";
import { MainContainer, TermText, Heading, Bold } from "./Terms.elements";

const Terms = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <MainContainer>
      <TermText>
        Thank you for visiting the TalkAsiaVoip website. ("Site"). The Site is
        an Internet Property of TalkAsiaVoip, LLC. ("TalkAsiaVoip," "we" or
        "us"). By using and/or accessing the Site, you are agreeing to comply
        with and be bound by the following TalkAsiaVoip Website Terms and
        Conditions ("Terms and Conditions"). These Terms and Conditions are
        inclusive of the TalkAsiaVoip Website Privacy Policy ("Privacy Policy"),
        as well as those supplemental terms and conditions, policies, agreements
        and/or other applicable documents that are expressly incorporated herein
        by reference (collectively, the "Agreement"). Please review the terms of
        the Agreement carefully. If you do not agree to the terms of the
        Agreement in their entirety, you are not authorized to use the Site
        and/or Site Offerings (as defined below) in any manner or form.
      </TermText>

      <TermText>
        <Heading>Scope of Agreement.</Heading>
        You agree to the terms and conditions outlined in the Agreement with
        respect to your use of the Site and/or Site Offerings. The Agreement
        constitutes the entire and only agreement between you and TalkAsiaVoip
        with respect to your use of the Site and/or Site Offerings, and
        supersedes all prior or contemporaneous agreements, representations,
        warranties and/or understandings with respect to the Site and/or Site
        Offerings. The Agreement does not govern your use of the TalkAsiaVoip
        Services (as defined below). Separate agreements, such as the
        TalkAsiaVoip Master Services Agreement, TalkAsiaVoip End User License
        Agreement ("EULA"), TalkAsiaVoip Scope, Order Form and Credit Card
        Authorization (collectively, "Services Agreements"), govern your use of
        each of the TalkAsiaVoip Services, contained separately on the Site.
        Your use of the services constitute acceptance of the TalkAsiaVoip terms
        of the EULA and MSA as applicable. Unless explicitly stated otherwise,
        any future offer(s) made available to you on the Site that augment(s) or
        otherwise enhance(s) the current features of the Site and/or Site
        Offerings shall be subject to the Agreement.
      </TermText>
      <TermText>
        <Heading>Modification of Agreement.</Heading>
        We may amend the Agreement from time to time in our sole discretion,
        without specific notice to you; provided, however, that any amendment or
        modification to the provisions applicable to dispute resolution
        (collectively, "Dispute Resolution Provisions") shall not apply to any
        disputes arising prior to the applicable amendment or modification. The
        latest Agreement will be posted on the Site, and you should review the
        Agreement prior to using the Site and/or Site Offerings. By your
        continued use of the Site and/or Site Offerings, you hereby agree to
        comply with all of the terms and conditions contained within the
        Agreement effective at that time (other than with respect to disputes
        arising prior to the amendment or modification of the Dispute Resolution
        Provisions, which shall be governed by the Dispute Resolution Provisions
        then in effect at the time of the subject dispute). Therefore, you
        should regularly check these Terms and Conditions for updates and/or
        changes.
      </TermText>
      <TermText>
        <Heading>Requirements.</Heading>
        The Site and Site Offerings are available only to authorized
        representatives of valid legal entities and/or individuals who can enter
        into legally binding contracts under applicable law. The Site and Site
        Offerings are not intended for use by non-valid legal entities and/or
        individuals under eighteen (18) years of age (or the applicable age of
        majority, if greater than eighteen (18) years of age). If an end-user
        visitor is not an authorized representative of a valid legal entity, is
        under eighteen (18) years of age (or the applicable age of majority, if
        greater than eighteen (18) years of age) and/or is unable to enter into
        legally binding contracts under applicable law, that end-user visitor
        does not have permission to use and/or access the Site and/or Site
        Offerings. End-user visitors shall be responsible, at all times, for
        ensuring that they have an Internet connection and/or other equipment
        necessary to access and use the Site and/or Site Offerings.
      </TermText>
      <TermText>
        <Heading>
          Description of the Site, Site Offerings and TalkAsiaVoip Services.
        </Heading>
        The Site contains: (a) general descriptions of certain of TalkAsiaVoip's
        software, platforms, technology and communications services including,
        without limitation, the Software (as defined in the EULA) (collectively,
        the "TalkAsiaVoip Services"); (b) links to certain of TalkAsiaVoip's
        customer support features for clients utilizing the Services; (c) links
        to certain TalkAsiaVoip social media pages; and (d) contact information/methods
        that provide end-user visitors with the means to: (i) contact
        TalkAsiaVoip regarding the Services; and (ii) request access to the
        TalkAsiaVoip Services (collectively, the "Site Offerings"). In order to
        submit a contact form and/or request a demo of any applicable Software
        and/or TalkAsiaVoip Services, you must submit some or all of the
        following information: (A) first and last name; (B) e-mail address; (C)
        telephone number; (D) company name (if applicable); (E) company size (if
        applicable); (F) any comments that you wish to submit; and (G) any and
        all other information requested on the applicable form (collectively,
        "Registration Data"). Upon entering your Registration Data and clicking
        on the applicable submission button on the Site, you may be contacted by
        TalkAsiaVoip personnel regarding your request.
      </TermText>
      <TermText>
        <Heading>License Grant.</Heading>
        As a user of the Site, you are granted a non-exclusive,
        non-transferable, revocable and limited license to access and use the
        Site, Site Offerings and associated content in accordance with the
        Agreement. TalkAsiaVoip may terminate this license at any time for any reason.
        You may use the Site and/or Site Offerings for your own personal,
        non-commercial use, except as provided for by a business transcation
        subject to payment terms, and End User License Agreement, "EULA" and
        Master Services Agreement "MSA". No part of the Site and/or Site
        Offerings may be reproduced in any form or incorporated into any
        information retrieval system, electronic or mechanical. You may not use,
        copy, emulate, clone, rent, lease, sell, modify, decompile, disassemble,
        reverse engineer or transfer the Site and/or Site Offerings, any content
        featured therein or any portion thereof. You may not use any automated
        means or form of scraping or data extraction to access, query or
        otherwise collect information from the Site and/or Site Offerings except
        as expressly permitted by TalkAsiaVoip. TalkAsiaVoip reserves any rights not explicitly
        granted in the Agreement. You may not use any device, software or
        routine to interfere or attempt to interfere with the proper working of
        the Site and/or Site Offerings. You may not take any action that imposes
        an unreasonable or disproportionately large load on TalkAsiaVoip infrastructure.
        Your right to use the Site and Site Offerings is not transferable.
      </TermText>
      <TermText>
        <Heading>Proprietary Rights.</Heading>
        The content, organization, graphics, design, compilation, magnetic
        translation, digital conversion, software, services and other matters
        related to the Site and Site Offerings are protected under applicable
        copyrights, trademarks and other proprietary (including, but not limited
        to, intellectual property) rights. The copying, redistribution,
        publication or sale by you of any part of the Site and/or Site Offerings
        is strictly prohibited. You do not acquire ownership rights in or to any
        content, document, software, services or other materials viewed at or
        through the Site and/or Site Offerings. The "TalkAsiaVoip" name and logo are
        registered trademarks of TalkAsiaVoip, LLC. The posting of information or
        material on or through the Site and/or Site Offerings by TalkAsiaVoip does not
        constitute a waiver of any right in or to such information and/or
        materials.
      </TermText>
      <TermText>
        <Heading>Editing, Deleting and Modification.</Heading>
        We reserve the right in our sole discretion to edit and/or delete any
        documents, information or other content appearing on or through the Site
        and/or Site Offerings.
      </TermText>
      <TermText>
        <Heading>Indemnification.</Heading>
        You agree to indemnify and hold TalkAsiaVoip, its parents, subsidiaries and
        affiliates, and each of their respective members, officers, directors,
        employees, agents, co-branders and/or other partners, harmless from and
        against any and all claims, expenses (including reasonable attorneys'
        fees), damages, suits, costs, demands and/or judgments whatsoever, made
        by any third party due to or arising out of: (a) your use of the Site
        and/or Site Offerings; (b) your breach of the Agreement; and/or (c) any
        dispute between you and any third party or other entity. The provisions
        of this Section 8 are for the benefit of TalkAsiaVoip, its parents, subsidiaries
        and/or affiliates, and each of their respective officers, directors,
        members, employees, agents, shareholders, licensors, suppliers and/or
        attorneys. Each of these individuals and entities shall have the right
        to assert and enforce these provisions directly against you on its own
        behalf.
      </TermText>
      <TermText>
        <Heading>Disclaimer of Warranties.</Heading>
        THE SITE, SITE OFFERINGS AND/OR ANY OTHER PRODUCTS AND/OR SERVICES THAT
        YOU MAY INQUIRE ABOUT AND/OR APPLY FOR THROUGH SAME ARE PROVIDED TO YOU
        ON AN "AS IS" AND "AS AVAILABLE" BASIS AND ALL WARRANTIES, EXPRESS AND
        IMPLIED, ARE DISCLAIMED TO THE FULLEST EXTENT PERMISSIBLE PURSUANT TO
        APPLICABLE LAW (INCLUDING, BUT NOT LIMITED TO, THE DISCLAIMER OF ANY
        WARRANTIES OF MERCHANTABILITY, NON-INFRINGEMENT OF INTELLECTUAL PROPERTY
        AND/OR FITNESS FOR A PARTICULAR PURPOSE). IN PARTICULAR, BUT NOT AS A
        LIMITATION THEREOF, TalkAsiaVoip MAKES NO WARRANTY THAT: (A) THE SITE, SITE
        OFFERINGS AND/OR ANY OTHER PRODUCTS AND/OR SERVICES THAT YOU MAY INQUIRE
        ABOUT AND/OR APPLY FOR THROUGH SAME WILL MEET YOUR REQUIREMENTS; (B) THE
        SITE, SITE OFFERINGS AND/OR ANY OTHER PRODUCTS AND/OR SERVICES THAT YOU
        MAY INQUIRE ABOUT AND/OR APPLY FOR THROUGH SAME WILL BE UNINTERRUPTED,
        TIMELY, SECURE OR ERROR-FREE; (C) YOU WILL QUALIFY FOR ANY OF THE TalkAsiaVoip
        SERVICES; OR (D) THE RESULTS THAT MAY BE OBTAINED FROM THE USE OF THE
        SITE, WILL BE ACCURATE OR RELIABLE. THE SITE AND/OR SITE OFFERINGS MAY
        CONTAIN BUGS, ERRORS, PROBLEMS OR OTHER LIMITATIONS. WE WILL NOT BE
        LIABLE FOR THE AVAILABILITY OF THE UNDERLYING INTERNET/MOBILE CONNECTION
        ASSOCIATED WITH THE SITE AND/OR SITE OFFERINGS. NO ADVICE OR
        INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED BY YOU FROM TalkAsiaVoip OR
        OTHERWISE THROUGH OR FROM THE SITE AND/OR SITE OFFERINGS SHALL CREATE
        ANY WARRANTY NOT EXPRESSLY STATED IN THE AGREEMENT.
      </TermText>
      <TermText>
        <Heading>Limitation of Liability.</Heading>
        YOU EXPRESSLY UNDERSTAND AND AGREE THAT TalkAsiaVoip SHALL NOT BE LIABLE TO YOU
        OR ANY THIRD PARTY FOR ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL,
        CONSEQUENTIAL AND/OR EXEMPLARY DAMAGES INCLUDING, BUT NOT LIMITED TO,
        DAMAGES FOR LOSS OF PROFITS, GOODWILL, USE, DATA OR OTHER INTANGIBLE
        LOSSES (EVEN IF TalkAsiaVoip HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH
        DAMAGES), TO THE FULLEST EXTENT PERMISSIBLE BY LAW FOR: (A) THE USE OR
        THE INABILITY TO USE THE SITE, SITE OFFERINGS AND/OR ANY OTHER PRODUCTS
        AND/OR SERVICES THAT YOU MAY INQUIRE ABOUT AND/OR APPLY FOR THROUGH
        SAME; (B) THE COST OF PROCUREMENT OF SUBSTITUTE GOODS AND SERVICES
        RESULTING FROM ANY GOODS, DATA, INFORMATION AND/OR SERVICES OBTAINED
        FROM, OR TRANSACTIONS ENTERED INTO THROUGH, THE SITE AND/OR SITE
        OFFERINGS; (C) THE FAILURE TO QUALIFY FOR ANY OF THE TalkAsiaVoip SERVICES; (D)
        THE UNAUTHORIZED ACCESS OR USE OF YOUR REGISTRATION DATA; AND (E) ANY
        OTHER MATTER RELATING TO THE SITE, SITE OFFERINGS AND/OR ANY OTHER
        PRODUCTS AND/OR SERVICES THAT YOU MAY INQUIRE ABOUT AND/OR APPLY FOR
        THROUGH SAME. THIS LIMITATION APPLIES TO ALL CAUSES OF ACTION, IN THE
        AGGREGATE INCLUDING, BUT NOT LIMITED TO, BREACH OF CONTRACT, BREACH OF
        WARRANTY, NEGLIGENCE, STRICT LIABILITY, MISREPRESENTATIONS AND ANY AND
        ALL OTHER TORTS. YOU HEREBY RELEASE TalkAsiaVoip FROM ANY AND ALL OBLIGATIONS,
        LIABILITIES AND CLAIMS IN EXCESS OF THE LIMITATION STATED HEREIN. IF
        APPLICABLE LAW DOES NOT PERMIT SUCH LIMITATION, THE MAXIMUM LIABILITY OF
        TalkAsiaVoip TO YOU UNDER ANY AND ALL CIRCUMSTANCES WILL BE FIVE HUNDRED DOLLARS
        ($500.00). THE NEGATION OF DAMAGES SET FORTH ABOVE IS A FUNDAMENTAL
        ELEMENT OF THE BASIS OF THE BARGAIN BETWEEN YOU AND TalkAsiaVoip. THE SITE, SITE
        OFFERINGS AND/OR ANY OTHER PRODUCTS AND/OR SERVICES THAT YOU MAY INQUIRE
        ABOUT AND/OR APPLY FOR THROUGH SAME WOULD NOT BE PROVIDED TO YOU WITHOUT
        SUCH LIMITATIONS.
      </TermText>
      <TermText>
        <Heading>Third Party Websites.</Heading>
        The Site may provide and/or refer you to links to other Internet
        websites and/or resources, including third party social media websites.
        Because TalkAsiaVoip has no control over such third party websites and/or
        resources, you hereby acknowledge and agree that TalkAsiaVoip is not responsible
        for the availability of such third party websites and/or resources.
        Furthermore, TalkAsiaVoip does not endorse, and is not responsible or liable
        for, any terms and conditions, privacy policies, content, advertising,
        services, products and/or other materials at or available from such
        third party websites or resources, or for any damages and/or losses
        arising therefrom.
      </TermText>
      <TermText>
        <Heading>Privacy Policy.</Heading>
        Use of the Site, Site Offerings and all Registration Data, comments,
        feedback, information and/or materials that you submit through or in
        association with same, are subject to our Privacy Policy. We reserve the
        right to use all information regarding your use of the Site and/or Site
        Offerings, and any and all other personally identifiable information
        provided by you, in accordance with the terms of our Privacy Policy. To
        view our Privacy Policy, Click Here.
      </TermText>
      <TermText>
        <Heading>Legal Warning.</Heading>
        Any attempt by any individual, whether or not a TalkAsiaVoip. customer, to
        damage, destroy, tamper with, vandalize and/or otherwise interfere with
        the operation of the Site and/or Site Offerings, is a violation of
        criminal and civil law and TalkAsiaVoip will diligently pursue any and all
        remedies in this regard against any offending individual or entity to
        the fullest extent permissible by law and in equity.
      </TermText>
      <TermText>
        <Heading>Dispute Resolution Provisions.</Heading>
        The Agreement shall be treated as though it were executed and performed
        in the State of California and shall be governed by and construed in
        accordance with the laws of the State of California (without regard to
        conflict of law principles). The parties hereto specifically consent to
        the personal jurisdiction of the state or federal courts located in the
        State of California for all disputes arising out of or related to the
        Agreement.
      </TermText>
      <TermText>
        <Heading>Miscellaneous.</Heading>
        Should any part of the Agreement be held invalid or unenforceable, that
        portion shall be construed consistent with applicable law and the
        remaining portions shall remain in full force and effect. To the extent
        that anything in or associated with the Site and/or any Site Offering is
        in conflict or inconsistent with the Agreement, the Agreement shall take
        precedence. Notwithstanding the foregoing, to the extent that there is
        any inconsistency between these Terms and Conditions and any Services
        Agreement, insofar as the applicable TalkAsiaVoip Services are concerned, the
        Services Agreement shall govern. Our failure to enforce any provision of
        the Agreement shall not be deemed a waiver of such provision nor of the
        right to enforce such provision. The parties do not intend that any
        agency or partnership relationship be created through operation of the
        Agreement.
      </TermText>
      <TermText>
        <Heading>Dispute Resolution Provisions.</Heading>
        The Agreement shall be treated as though it were executed and performed
        in the State of California and shall be governed by and construed in
        accordance with the laws of the State of California (without regard to
        conflict of law principles). The parties hereto specifically consent to
        the personal jurisdiction of the state or federal courts located in the
        State of California for all disputes arising out of or related to the
        Agreement.
      </TermText>
    </MainContainer>
  );
};

export default Terms;
