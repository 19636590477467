import styled from "styled-components";

export const MainContainer = styled.div`
  max-width: 837px;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 0 auto;

  @media screen and (max-width: 920px) {
    padding: 40px 0px;
  }
`;

export const TermText = styled.p`
  font-family: "Poppins";
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 26px;
  text-align: left;
  color: #787878;
  margin-bottom: 20px;
`;

export const Heading = styled.h2`
  font-family: "Poppins";
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  text-align: center;
  color: #000;
  margin: 24px 0px 16px 0px;
`;

export const Bold = styled.span`
  font-weight: bold;
`;

export const BoldHeading = styled.h4` 
  font-family: "Poppins";
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 26px;
  text-align: left;
  color: #787878;
  text-decoration: underline;
  font-weight: bold; 
`;

// export const MainContainer = styled.div``;
// export const MainContainer = styled.div``;
