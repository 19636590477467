import styled from "styled-components";

export const MainContainer = styled.div`
  /* max-width: 1440px; */
  max-width: 100%;
  min-height: 917px;
  width: 100%;
  display: flex;
  /* flex-direction: column; */
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  margin: 0 auto;
  background: #f1fff7;

  @media screen and (max-width: 690px) {
    justify-content: center;
  }

  @media screen and (max-width: 1040px) {
    padding: 40px 0px;
  }
`;

export const SubContainer = styled.div`
  max-width: 1225px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding-top: 136px;
  padding-bottom: 115px;

  @media screen and (max-width: 1040px) {
    flex-direction: column;
  }
`;

export const HighlightSec = styled.div`
  max-width: 387px;
  width: 100%;
  height: 917px;
  display: flex;
  align-items: flex-start;

  @media screen and (max-width: 1040px) {
    height: 300px;
    max-width: 100%;
    justify-content: center;
    align-items: center;
  }
`;

export const SingleHighlight = styled.div`
  @media screen and (max-width: 1040px) {
    justify-content: center;
    align-items: center;
    text-align: center;
  }
`;

export const HighlightHeading = styled.h2`
  font-family: "Poppins";
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  color: #262626;
`;

export const HighlightText = styled.h4`
  font-family: "Poppins";
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 56px;
  color: #262626;
  margin-bottom: 38px;
`;

export const HighlightBtn = styled.button`
  width: 160px;
  height: 55px;
  background: #262626;
  border-radius: 42px;
  border: none;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  text-align: center;
  color: #ffffff;
`;

export const FeatureSec = styled.div`
  max-width: 695px;
  width: 60%;
  height: 917px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;

  @media screen and (max-width: 1040px) {
    justify-content: space-between;
  }
`;

export const FeatureCol = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-evenly;
`;

export const SingleFeature = styled.div`
  max-width: 300px;
  width: 100%;
  height: 186px;
`;

export const FeatureImage = styled.img`
  margin-bottom: 35px;
`;

export const FeatureHeading = styled.h2`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 25px;
  color: #000000;
  margin: 0px;
  margin-bottom: 13px;
`;

export const FeatureDesc = styled.p`
  font-family: "Poppins";
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 26px;
  color: #959595;
`;

// export const div = styled.div``;
// export const div = styled.div``;
// export const div = styled.div``;
