import { Link } from "react-router-dom";
import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai";

import styled from "styled-components";

export const MainContainer = styled.div`
  height: 140px;
  max-width: 1209px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  margin: 0 auto;

  @media screen and (max-width: 690px) {
    height: 84px;
    justify-content: space-between;
  }

  @media screen and (max-width: 480px) {
    height: 46px;
  }

  @media screen and (max-width: 1200px) {
    padding: 0px 20px;
  }

  @media screen and (max-width: 680px) {
    padding-top: 20px;
    margin-bottom: 20px;
  }
  @media screen and (max-width: 980px) {
    background: #36a469;
    margin-bottom: 0px;
  }
`;

export const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  margin-right: 50px;
`;

export const Home = styled(Link)`
  cursor: pointer;
`;

export const Logo = styled.img`
  @media screen and (max-width: 480px) {
    width: 44px;
  }
`;

export const MainNavContainer = styled.div`
  width: 100%;

  @media screen and (max-width: 690px) {
    display: none;
  }
`;

export const ChatContainer = styled.div``;
export const NavItems = styled.ul`
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  max-width: 438px;
`;

export const NavItem = styled.li`
  &:first-child {
    position: relative;
  }
`;

export const SingleNavLink = styled(Link)`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  color: ${(props) => (props.color ? props.color : "#fff")};
  text-decoration: none;
`;

export const Button = styled(Link)`
  height: 55px;
  width: 160px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  background: #262626;
  border: none;
  outline: none;
  border-radius: 42px 0px 42px 42px;
  text-decoration: none;

  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #ffffff;
  cursor: pointer;
`;

export const Icon = styled.img``;
export const Text = styled.div``;

export const MobMenu = styled(AiOutlineMenu)`
  font-size: 32px;

  @media screen and (max-width: 480px) {
    width: 22px;
  }
`;
export const MobMenuClose = styled(AiOutlineClose)`
  font-size: 42px;
  color: #fff;

  @media screen and (max-width: 480px) {
    width: 22px;
  }
`;
export const MobMenuContainer = styled.div`
  height: 100vh;
  width: 100%;
  background: #36a469;
  padding: 30px 20px;
`;
export const MobMenuLinksCont = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  list-style: none;

  ${NavItem} {
    margin-bottom: 30px;
  }

  ${SingleNavLink} {
    color: #fff;
    font-size: 1.3em;
    font-weight: 600;
  }
`;

export const DropDown = styled.div`
 
  width: 320px;
  display: flex;
  flex-direction: column;

  justify-content: space-between;
  position: absolute;
  top: 44px;
  left: -20px;
  background: #ffffff;
  box-shadow: 0px 0px 94px 10px rgba(187, 187, 187, 0.1);
  border-radius: 10px;
  /* padding: 40px 41px; */
  padding: 20px 21px;
  z-index: 9999;
`;

export const TopArrow = styled.div`
  width: 26px;
  height: 26px;
  position: absolute;
  top: -5px;
  left: 39px;
  z-index: -999;
  background: #fff;
  transform: rotate(45deg);
`;

export const DropItem = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-decoration: none;
  padding: 12px 16px;

  :hover {
    background: rgb(241 241 241);
  }
`;

export const DropImg = styled.img``;

export const DropInfo = styled.div``;

export const DropHeading = styled.h2`
  width: 192px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: bold;
  font-size: 17px;
  color: #16133d;
`;

export const DropSubHeading = styled.h4`
  font-family: "Roboto";
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  color: #9c9c9c;
`;

// export const MainContainer = styled.div``;
// export const MainContainer = styled.div``;
// export const MainContainer = styled.div``;
// export const MainContainer = styled.div``;
// export const MainContainer = styled.div``;
