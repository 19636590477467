import React from "react";
import {
  MainContainer,
  HeadingCont,
  Heading,
  SubHeading,
  FeaturesCont,
  SingleFeature,
  FeatureIcon,
  FeatureNumbers,
  FeatureTitle,
  FeatureSubTitle,
} from "./AboutSecThree.elements";

const AboutSecThree = () => {
  return (
    <>
      <MainContainer>
        <HeadingCont>
          <Heading>Monthly activity in TalkAsia</Heading>
          <SubHeading>Thank you to our amazing customers</SubHeading>
        </HeadingCont>

        <FeaturesCont>
          <SingleFeature>
            <FeatureIcon src="assets/images/about-3-1.svg" />
            <FeatureNumbers>+500K</FeatureNumbers>
            <FeatureTitle>Successes</FeatureTitle>
            <FeatureSubTitle>Dialing with flying colors!</FeatureSubTitle>
          </SingleFeature>

          <SingleFeature>
            <FeatureIcon src="assets/images/about-3-2.svg" />
            <FeatureNumbers>+300M</FeatureNumbers>
            <FeatureTitle>Calls</FeatureTitle>
            <FeatureSubTitle>Now that's what we call great</FeatureSubTitle>
          </SingleFeature>

          <SingleFeature>
            <FeatureIcon src="assets/images/about-3-3.svg" />
            <FeatureNumbers>+45 &nbsp;</FeatureNumbers>
            <FeatureTitle>Countries served</FeatureTitle>
            <FeatureSubTitle>
              Our clients come from all over the world
            </FeatureSubTitle>
          </SingleFeature>
        </FeaturesCont>
      </MainContainer>
    </>
  );
};

export default AboutSecThree;
