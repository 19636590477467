import React from "react";
import {
  MainContainer,
  InfoCont,
  Heading,
  Desc,
  InfoLink,
  ImageCont,
  Image,
} from "./ServiceWithImage.elements";

const ServiceWithImage = ({
  heading,
  desc,
  linkText,
  image,
  imageAlign,
  width,
}) => {
  return (
    <>
      <MainContainer imageAlign={imageAlign}>
        <InfoCont>
          <Heading width={width}>{heading}</Heading>
          <Desc>{desc}</Desc>
          <InfoLink>{linkText}</InfoLink>
        </InfoCont>

        <ImageCont>
          <Image src={image} />
        </ImageCont>
      </MainContainer>
    </>
  );
};

export default ServiceWithImage;
