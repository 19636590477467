import React from "react";
import {
  MainContainer,
  HeadingCont,
  Heading,
  SubHeading,
  FeatureCont,
  SingleFeature,
  Icon,
  Title,
  Desc,
} from "./FeaturesecOne.elements";

const FeatureSecOne = () => {
  return (
    <>
      <MainContainer>
        <HeadingCont>
          <Heading>Your outbound calls on auto pilot</Heading>
          <SubHeading>
            TalkAsia is a web-based/softphone based dialer solution for
            telemarketing, fundraising and appointment scheduling businesses.
          </SubHeading>
        </HeadingCont>

        <FeatureCont>
          <SingleFeature>
            <Icon src="assets/images/feature-1-1.svg" />
            <Title>Focus only on valuable activities</Title>
            <Desc>
              Make sure you only speak to top-quality leads. Don't waste time on
              busy lines.
            </Desc>
          </SingleFeature>

          <SingleFeature>
            <Icon src="assets/images/feature-1-2.svg" />
            <Title>Manage your leads effortlessly</Title>
            <Desc>
              Pick and assign leads to the right campaigns. Automate your lead
              journeys and flows.
            </Desc>
          </SingleFeature>

          <SingleFeature>
            <Icon src="assets/images/feature-1-3.svg" />
            <Title>Improve brand awareness & recall</Title>
            <Desc>
              Delivered the right way, outbound calling can give a positive
              image to your brand or company.
            </Desc>
          </SingleFeature>

          <SingleFeature>
            <Icon src="assets/images/feature-1-4.svg" />
            <Title>Automate repetitive tasks & actions</Title>
            <Desc>
              Create your own automated workflows. Waste no more time on
              repetitive tasks.
            </Desc>
          </SingleFeature>
        </FeatureCont>
      </MainContainer>
    </>
  );
};

export default FeatureSecOne;
