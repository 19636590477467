import styled from "styled-components";

export const MainContainer = styled.div`
  /* height: 292px; */
  max-width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 0 auto;

  @media screen and (max-width: 690px) {
  }
`;

export const Heading = styled.h2`
  max-width: 676px;
  width: 100%;
  font-family: "Poppins";
  font-style: normal;
  font-weight: bold;
  font-size: 44px;
  text-align: center;
  color: #424242;
  margin-bottom: 19px;

  @media screen and (max-width: 640px) {
    font-size: 32px;
  }
`;

export const SubHeading = styled.h4`
  max-width: 457px;
  width: 100%;
  font-family: "Poppins";
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 26px;
  text-align: center;
  color: #787878;
  margin-bottom: 38px;

  @media screen and (max-width: 640px) {
    font-size: 13px;
    padding: 0px 20px;
  }
`;

export const BubbleCont = styled.div`
  width: 100%;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: -999;

  @media screen and (max-width: 690px) {
    display: none;
  }
`;

export const BubbleLeft = styled.img``;

export const BubbleRight = styled.img``;

export const PolicyHeading = styled.h2`
  font-family: "Poppins";
  font-style: normal;
  font-weight: bold;
  font-size: 29px;
  color: #36a369;
  margin-bottom: 13px;
`;

export const PolicyText = styled.p`
  max-width: 675px;
  width: 100%;
  font-family: "Poppins";
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 26px;
  color: #787878;
  text-align: center;
  margin-bottom: 80px;
`;

// export const MainContainer = styled.div``;
// export const MainContainer = styled.div``;
// export const MainContainer = styled.div``;
// export const MainContainer = styled.div``;
// export const MainContainer = styled.div``;
// export const MainContainer = styled.div``;
// export const MainContainer = styled.div``;
// export const MainContainer = styled.div``;
