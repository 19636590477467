import React from "react";
import {
  Navbar,
  Hero,
  ReviewIcon,
  FeatureSecOne,
  FeatureSecTwo,
  FeatureSecThree,
  InfoSec,
  LeadSec,
  Footer,
} from "../Components";

const HomePage = () => {
  const hero = {
    heading: "Improve outbound calls today",
    subHeading:
      "We help call centers boost KPIs, make better insight-driven decisions, and manage contacts smarter.",
    image: "/assets/images/header-img.svg",
  };
  return (
    <>
      <Navbar />
      <Hero
        heading={hero.heading}
        subHeading={hero.subHeading}
        image={hero.image}
      />
      <ReviewIcon />
      <FeatureSecOne />
      <FeatureSecTwo />
      <FeatureSecThree />
      <InfoSec />
      <LeadSec />
      <Footer />
    </>
  );
};

export default HomePage;
