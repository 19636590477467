import React from "react";
import { MainContainer, TextCont, Text } from "./AboutSecOne.elements";

const AboutSecOne = () => {
  return (
    <>
      <MainContainer>
        <TextCont>
          <Text>
            We're a team of creators and innovators of outbound call center who
            help you grow in every stage of your business to convert more leads
            using the latest innovations in cloud-based omnichannel contact
            center software.
            <br /> <br />
            What do you do when you're working in telemarketing but are
            frustrated by the ways of doing things? Especially when the software
            provider that is supposed to ease your work don't give you good
            support to work efficiently? <br />
            <br />
            You switch to smarter solutions provider.
            <br />
            <br />
            TalkAsia has built an amazing team that has resulted in great
            communication and synergy. Our team nerds out over new technology,
            often debating what's the latest and greatest, and the best way to
            get something done.
            <br />
            <br />
            It all started in 2007 with an idea of offering reliable voice
            services to clients all over the world. TalkAsia was small but was
            built to make phone support easy to manage – accessible,
            transparent, and collaborative.
            <br />
            <br />
            Today, we are a dedicated team that works every day to create the
            best possible solution for our customers. We believe in user-driven
            innovation, and our customers have made us understand the need to
            engage with clients in a whole new way. That is why we continuously
            listen to their needs and feedback.
          </Text>
        </TextCont>
      </MainContainer>
    </>
  );
};

export default AboutSecOne;
