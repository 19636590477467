import React from "react";
import {
  MainContainer,
  HeadingCont,
  Heading,
  Desc,
  FormSec,
  LeadButton,
  LeadBtnImg,
} from "./LeadSec.elements";

const LeadSec = () => {
  return (
    <>
      <MainContainer>
        <HeadingCont>
          <Heading>Ready to have more impactful conversations?</Heading>
          <Desc>Be up and running in just a few minutes!</Desc>
        </HeadingCont>

        {/* <FormSec>
          <Form>
            <TextInput placeholder="Name" />
            <TextInput placeholder="Country Code + Phone Number" />
            <SubmitBtn>Submit</SubmitBtn>
          </Form>
        </FormSec> */}

        <FormSec>
          <LeadButton to="/contact-us">
            <LeadBtnImg src="assets/images/lead-btn-bubble.svg" />
            Let’s Talk
          </LeadButton>
        </FormSec>
      </MainContainer>
    </>
  );
};

export default LeadSec;
