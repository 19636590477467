import React, { useEffect } from "react";
import {
  Navbar,
  Hero,
  NewSection,
  ServicesWithImage,
  VoipFeatureSec,
  LeadSec,
  Footer,
} from "../Components";

const VoipPage = () => {
  const hero = {
    heading: "A complete voice solution from a single provider",
    subHeading:
      "Customized Voice and Connectivity. One Provider. One Bill. One Point of Contact. 24/7 Support.",
    image: "/assets/images/voip-header-img.svg",
    headingSize: "34px",
  };

  const NewFeatures = {
    heading: "Our voice solutions are the foundation for your communications.",
    subHeading: "VOICE COMMUNICATIONS",

    featureOneHeading: "Voice Termination",
    featureOneDesc:
      "Connect directly to the highest quality tier 1 independent network with SIP connectivity options for all business types like enterprise and call centers",
    featureOneIcon: "/assets/images/new-feature-1.svg",

    featureTwoHeading: "Voice Origination",
    featureTwoDesc:
      "Give your customers and prospects an easy and reliable way to reach you. Focus on your customers with dependable origination service.",
    featureTwoIcon: "/assets/images/new-feature-2.svg",

    featureThreeHeading: "SIP Trunking",
    featureThreeDesc: [
      "Call local,  long distance or toll-free with an exceptional voice experience. We can scale to meet any capacity requirements.",
      <br />,
      <br />,
    ],
    featureThreeIcon: "/assets/images/new-feature-3.svg",

    featureFourHeading: "STIR/SHAKEN",
    featureFourDesc:
      "Mitigate spoofed robocalls and add call treatment for outbound calls. Reduce risk with caller validation for improved customer experiences",
    featureFourIcon: "/assets/images/new-feature-4.svg",
  };

  const voipSecTwo = {
    heading: "Guarantee an exceptional customer experience.",
    desc: "You can count on TalkAsia’s voice services to facilitate great customer engagement day-after-day while supporting you and your team as you grow. Our complete suite of solutions are sure to cover all of your voice communications requirements.",
    // linkText: "Book a free Call",
    image: "assets/images/voip-2.svg",
    imageAlign: "row",
    width: "590px",
  };

  const voipSecThree = {
    heading: "Rest assured our support team and tools will not fail you.",
    desc: "Your business’s success is of the utmost importance to us. Our knowledgeable support team is always willing to answer your questions. Easily manage your services with our secure customer portals. We take your business’ health and growth to heart and we want to be the partner you can always depend upon and trust.",
    // linkText: "Book a free Call",
    image: "assets/images/voip-3.png",
    imageAlign: "row-reverse",
  };


  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Navbar />
      <Hero
        heading={hero.heading}
        subHeading={hero.subHeading}
        image={hero.image}
        headingSize={hero.headingSize}
      />
      <NewSection
        position="left"
        heading={NewFeatures.heading}
        subHeading={NewFeatures.subHeading}
        featureOneHeading={NewFeatures.featureOneHeading}
        featureTwoHeading={NewFeatures.featureTwoHeading}
        featureThreeHeading={NewFeatures.featureThreeHeading}
        featureFourHeading={NewFeatures.featureFourHeading}
        featureOneDesc={NewFeatures.featureOneDesc}
        featureTwoDesc={NewFeatures.featureTwoDesc}
        featureThreeDesc={NewFeatures.featureThreeDesc}
        featureFourDesc={NewFeatures.featureFourDesc}
        featureOneIcon={NewFeatures.featureOneIcon}
        featureTwoIcon={NewFeatures.featureTwoIcon}
        featureThreeIcon={NewFeatures.featureThreeIcon}
        featureFourIcon={NewFeatures.featureFourIcon}
      />

      <ServicesWithImage
        heading={voipSecTwo.heading}
        desc={voipSecTwo.desc}
        linkText={voipSecTwo.linkText}
        image={voipSecTwo.image}
        imageAlign={voipSecTwo.imageAlign}
        width={voipSecTwo.width}
      />

      <ServicesWithImage
        heading={voipSecThree.heading}
        desc={voipSecThree.desc}
        linkText={voipSecThree.linkText}
        image={voipSecThree.image}
        imageAlign={voipSecThree.imageAlign}
      />

      {/* <ServicesSecOne
        items={itemsSecFive}
        title="Fulfill your communication needs"
        style={{ background: "#F1FFF7" }}
        height="761px"
      /> */}

      <VoipFeatureSec />

      <LeadSec />
      <Footer />
    </>
  );
};

export default VoipPage;
