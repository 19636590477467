import React, { useState, useEffect, useCallback } from "react";

import {
  MainContainer,
  SecOne,
  SecTwo,
  ContOne,
  Heading,
  FooterLink,
  ContTwo,
  MenuOne,
  ContactCont,
  ContHeading,
  ContactDetCont,
  SocialCont,
  CopyCont,
  PhoneCont,
  CountryIcon,
  ContactNum,
  SocialLink,
  SocialIcon,
  CopyText,
  BubbleRight,
  FG,
} from "./Footer.elements";

const Footer = () => {
  const names = ["Say Hello", "Di hola", "السلام عليكم"];
  const [newName, setnewName] = useState("Say Hello");

  const shuffle = useCallback(() => {
    const index = Math.floor(Math.random() * names.length);
    setnewName(names[index]);
  }, []);

  useEffect(() => {
    const intervalID = setInterval(shuffle, 2500);
    return () => clearInterval(intervalID);
  }, [shuffle]);

  return (
    <>
      <MainContainer>
        <SecOne>
          <ContOne>
            <MenuOne>
              <Heading>Products</Heading>
              <FooterLink to="/dialer">TalkAsia Dialer</FooterLink>
              <FooterLink to="/voip">TalkAsia Voip</FooterLink>
              <FooterLink to="/switch">TalkAsia Switch</FooterLink>
              {/* <FooterLink to="/amazon">Amazon Services</FooterLink> */}
            </MenuOne>

            <MenuOne>
              <Heading>Company</Heading>
              <FooterLink to="/about-us">About Us</FooterLink>
              <FooterLink to="/terms">Terms of use</FooterLink>
              <FooterLink to="/report-abuse">Report Abuse</FooterLink>
              <FooterLink to="/information-request" style={{}}>
                Law Inforcement Request
              </FooterLink>
            </MenuOne>
          </ContOne>

          <ContTwo>
            <MenuOne>
              <Heading>Help</Heading>
              <FooterLink to="/download">Downloads</FooterLink>
              <FooterLink to="/contact-us">Contact Us</FooterLink>
              <FooterLink to="#">&nbsp;</FooterLink>
            </MenuOne>

            <MenuOne>
              <Heading>CONNECT</Heading>
              <FooterLink
                onClick={() =>
                  window.open(
                    "https://www.facebook.com/TalkAsiaTech/",
                    "_blank"
                  )
                }
              >
                Facebook
              </FooterLink>
              <FooterLink
                onClick={() =>
                  window.open(
                    "https://www.linkedin.com/company/talkasiavoip-llc",
                    "_blank"
                  )
                }
              >
                LinkedIn
              </FooterLink>
              <FooterLink>Twitter</FooterLink>
            </MenuOne>
          </ContTwo>
        </SecOne>

        <SecTwo>
          <ContactCont>
            <ContHeading>{newName} 👋</ContHeading>

            <ContactDetCont>
              <PhoneCont>
                <CountryIcon src="assets/images/flag-1.svg" />
                <ContactNum>
                  +13022615199
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </ContactNum>
              </PhoneCont>

              <PhoneCont>
                <CountryIcon src="assets/images/flag-2.svg" />
                <ContactNum>+13022615199</ContactNum>
              </PhoneCont>

              <PhoneCont>
                <CountryIcon src="assets/images/flag-3.svg" />
                <ContactNum>sales@talkasiavoip.com</ContactNum>
              </PhoneCont>

              <PhoneCont>
                <CountryIcon src="assets/images/flag-4.svg" />
                <ContactNum>TalkAsiaVoip</ContactNum>
              </PhoneCont>
            </ContactDetCont>
            <SocialCont>
              <a href="https://www.facebook.com/TalkAsiaTech/" target="_blank">
                <SocialIcon src="assets/images/fb.svg" />
              </a>

              <a
                href="https://www.linkedin.com/company/talkasiavoip-llc"
                target="_blank"
              >
                <SocialIcon src="assets/images/linkdin.svg" />
              </a>
            </SocialCont>
            <CopyCont>
              <CopyText>
                Copyright © {new Date().getFullYear()} TalkAsia
              </CopyText>
              <a href="" target="_blank">
                <FG src="assets/images/fg.png" />
              </a>
            </CopyCont>
          </ContactCont>

          <BubbleRight src="assets/images/bubble-footer-right.svg" />
        </SecTwo>
      </MainContainer>
    </>
  );
};

export default Footer;
