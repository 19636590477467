import React, { useEffect } from "react";
import { Navbar, PageTopSec, ContactForm, Footer } from "../Components";

const ContactUsPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Navbar color="#535353" />
      <PageTopSec
        heading="Contact us"
        subHeading="Do you have any questions, requests, feedback or ideas you want to
          share with us? Please feel free to send us a message and we'll get in
          touch with you as soon as possible."
      />
      <ContactForm />
      <Footer />
    </>
  );
};

export default ContactUsPage;
