import styled from "styled-components";
import { Link } from "react-router-dom";

export const MainContainer = styled.div`
  max-width: 614px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  margin: 0 auto;
  padding: 60px 0px 400px 0px;

  @media screen and (max-width: 690px) {
    justify-content: center;
  }
`;

export const SingleDownload = styled.div`
  max-width: 300px;
  width: 100%;
  height: 63px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #ffffff;
  box-shadow: 0px 6px 47px rgba(38, 78, 118, 0.1);
  margin-bottom: 16px;
`;

export const InfoCont = styled.div`
  max-width: 341px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

export const IconCont = styled.div`
  width: 85px;
  height: 63px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${(props) => (props.background ? props.background : "#36a469")};
  box-shadow: 0px 6px 47px rgba(38, 78, 118, 0.1);
  cursor: pointer;
`;

export const DownloadImage = styled.img``;

export const InfoIcon = styled.img`
  margin-right: 6.04px;
`;

export const InfoName = styled.h4`
  font-family: "Poppins";
  font-style: normal;
  font-weight: bold;
  font-size: 31px;
  color: #16133d;
`;

// export const MainContainer = styled.div``;
// export const MainContainer = styled.div``;
// export const MainContainer = styled.div``;
// export const MainContainer = styled.div``;
