import styled from "styled-components";
import { Link } from "react-router-dom";

export const MainContainer = styled.div`
  min-height: 790px;
  /* max-width: 1440px; */
  max-width: 100%;
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #f1fff7;
  position: relative;
  @media screen and (max-width: 860px) {
    padding: 40px 0px;
  }
`;

export const HeadingCont = styled.div`
  margin-bottom: 28px;
`;

export const Heading = styled.h2`
  font-family: "Poppins";
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  line-height: 56px;
  color: #262626;
  text-align: center;
  margin-bottom: 26px;
`;

export const SubHeading = styled.h4`
  max-width: 439px;
  width: 100%;
  font-family: "Poppins";
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 26px;
  color: #383838;
  text-align: center;
`;

export const ContentCont = styled.div`
  max-width: 1164px;

  width: 100%;
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: 920px) {
    flex-direction: column;
  }
`;

export const MenuCont = styled.div`
  max-width: 420px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  @media screen and (max-width: 920px) {
    max-width: 100%;
    padding: 0px 20px;
  }
`;

export const UL = styled.ul`
  width: 100%;
  @media screen and (max-width: 920px) {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    border-bottom: 2px solid #ebebeb;
  }
`;

export const ListItem = styled.li`
  max-width: 420px;
  width: 100%;
  height: 136px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #fff;
  list-style: none;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 25px;
  line-height: 40px;
  color: #262626;
  margin-bottom: 25px;
  padding: 0px 39px;
  cursor: pointer;

  @media screen and (max-width: 920px) {
  }

  :hover {
    color: #36a469;
  }
`;

export const DetailsCont = styled.div`
  min-height: 455px;
  height: 100%;
  max-width: 643px;
  width: 100%;

  padding: 17px 0px 20px 49px;
  position: relative;
`;

export const DetHeading = styled.h2`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 34px;
  line-height: 56px;
  color: #262626;
  margin-bottom: 23px;
`;

export const DetDesc = styled.p`
  margin-bottom: 34px;
`;

export const CheckPointsCont = styled.div`
  max-width: 500px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 55px;
`;

export const SingleCheckPoint = styled.div`
  max-width: 244px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 6px;
`;

export const CheckIcon = styled.img`
  margin-right: 11px;
`;

export const CheckHeading = styled.div`
  font-family: "Poppins";
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 26px;
  color: #383838;
`;

export const ButtonCont = styled.div``;

export const GetStart = styled.button`
  width: 160px;
  height: 41px;
  background: #36a469;
  border-radius: 50px;
  outline: none;
  border: none;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  text-align: center;
  color: #ffffff;
  cursor: pointer;
  margin-right: 31px;
`;

export const Explore = styled(Link)`
  font-family: "Poppins";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #383838;
`;

export const BubbleRight = styled.img`
  position: absolute;
  right: 0;
  top: 65%;
`;

export const RatingImage = styled.img`
  margin-bottom: 10px;
`;

export const UserInfo = styled.div`
  display: flex;
`;

export const UserImage = styled.img`
  height: 55px;
  width: 55px;
  border-radius: 50%;
  margin-right: 19px;
`;

export const UserDet = styled.div``;

export const UserDetName = styled.h6`
  font-family: "DM Sans";
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  letter-spacing: -0.4px;
  color: #0f2137;
  line-height: 21px;
`;

export const UserDetDate = styled.h6`
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  letter-spacing: -0.375px;
  color: #36a469;
  line-height: 26px;
`;

export const SocialIcon = styled.img``;

// export const Desc =styled.div``;
