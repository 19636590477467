import HomePage from "./pages/HomePage";

import GlobalStyle from "./globalStyles";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import ServicesPage from "./pages/ServicesPage";
import AboutUsPage from "./pages/AboutUsPage";
import ContactUsPage from "./pages/ContactUsPage";
import InformationRequestPage from "./pages/InformationRequestPage";
import AbuseReportPage from "./pages/AbuseReportPage";
import DownloadPage from "./pages/DownloadPage";
import VoipPage from "./pages/VoipPage";
import SwitchPage from "./pages/SwitchPage";
import TermsPage from "./pages/TermsPage";
import ServiceAgreementPage from "./pages/ServiceAgreementPage";
import Cookie from "./Components/Cookie/Cookie";
import PriceTablesPage from "./pages/PriceTablesPage";
import AmazonPage from "./pages/AmazonPage";

const router = createBrowserRouter([
  {
    path: "/",
    element: <HomePage />,
  },
  {
    path: "/dialer",
    element: <ServicesPage />,
  },
  {
    path: "/about-us",
    element: <AboutUsPage />,
  },
  {
    path: "/contact-us",
    element: <ContactUsPage />,
  },
  {
    path: "/voip",
    element: <VoipPage />,
  },
  {
    path: "/switch",
    element: <SwitchPage />,
  },
  {
    path: "/terms",
    element: <TermsPage />,
  },
  {
    path: "/pricing",
    element: <PriceTablesPage />,
  },
  {
    path: "/amazon",
    element: <AmazonPage />,
  },
  {
    path: "/service-agreement",
    element: <ServiceAgreementPage />,
  },
  {
    path: "/information-request",
    element: <InformationRequestPage />,
  },
  {
    path: "/report-abuse",
    element: <AbuseReportPage />,
  },
  {
    path: "/download",
    element: <DownloadPage />,
  },
]);

function App() {
  return (
    <>
      <GlobalStyle />
      <Cookie />
      <RouterProvider router={router} />
    </>
  );
}

export default App;
