import React from "react";
import { Navbar, PageTopSec, Terms, Footer } from "../Components";

const TermsPage = () => {
  return (
    <>
      <Navbar color="#535353" />
      <PageTopSec heading='TALKASIAVOIP LLC® END USER LICENSE AGREEMENT ("EULA")' />
      <Terms />
      <Footer />
    </>
  );
};

export default TermsPage;
