import React from "react";
import { Navbar, PageTopSec, Download, Footer } from "../Components";

const DownloadPage = () => {
  return (
    <>
      <Navbar color="#535353" />
      <PageTopSec
        heading="Download Softwares"
        subHeading="Tools you need to have an impactful conversation"
      />
      <Download />
      <Footer />
    </>
  );
};

export default DownloadPage;
